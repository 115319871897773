import axios from "axios";
import { toast } from "react-toastify";
import { backendUrl } from "../../constant/BaseUrl";

const apiRequest = async ({ url, method }, data = null, params = null, id, status) => {
  // console.log("dhjigdfkjsmgygbhn m", { url, method }, data = null, params = null, id, status)
  let apiurl = backendUrl + url;


  if (url === "/delete-event" && method === "DELETE") {
    apiurl += '/' + id
  }
  if (url === "/contact-us" && method === "DELETE") {
    apiurl += '/' + id
  }


  let token = localStorage.getItem("auth-token")
    ? localStorage.getItem("auth-token")
    : "";
  try {
    // console.log("asfdyhausgyuhj", {
    //   url: apiurl,
    //   method,
    //   data,
    //   params,
    //   headers: {
    //     // AUthorization: `Bearer ` + token,
    //   },
    // })
    const response = await axios({
      url: apiurl,
      method,
      data,
      params,
      headers: {
        // AUthorization: `Bearer ` + token,
      },
    });
    return response.data;
  } catch (error) {
    // console.log("error?.response?.data", );
    toast(error?.response?.data?.message);
    if (error && error.response && error.response.data?.message === 'Bad token') {
      localStorage.clear();
      window.location.href = "/"
    }
    // toast.error(error.response.data.message);
    return { error: error.response.data };
  }
};

export { apiRequest };