import React from 'react'
import { Box } from '@mui/system';
import assetImages from '../../constant/enum';
import "./style.css"
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink, useNavigate } from 'react-router-dom';

const Header = (props) => {
  const { text } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Logout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <Box className='header-box-main'>
        <div className='inner-box-main-area'>
          <div className='inner-text-main'>{text}</div>
          <div className='right-side-inner-box'>
            {/* <div className='search-box'>
              <div className='search-input'>
                <input type="text" placeholder='Search Product' />
                <span><img src={assetImages.searchnormal} alt='search-icon' /></span>
              </div>
            </div>
            <div className='noti-box'>
              <div className='not-img'>
                <img src={assetImages.newnotification} alt="notification" />
                <span></span>
              </div>
            </div>
            <div className='noti-box'>
              <div className='not-img'>
                <img src={assetImages.newmail} alt="notification" />
              </div>
            </div> */}
            <div className='d-flex profile-box' aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}>
              <img className="profile-image" src={assetImages.avtar} alt="" />
              <p className='mb-0 pl-5'>Admin</p>
              <img src={assetImages.vector} alt="" />
            </div>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={Logout}>Logout</MenuItem>

            </Menu>
          </div>
        </div>
      </Box>
    </>
  )
}

export default Header