import { CircularProgress, Grid, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import assetImages from '../../constant/enum';
import { Link, NavLink, useParams } from "react-router-dom";
import { Typography } from "antd";
import DashboardLayout from "../../base/DashboardLayout";
import BreadCum from "../common/BreadCum";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { apiRequest } from "../../environment/Api/apiCall";
import { apipaths } from "../../environment/Api/apiPath";
import { toast } from "react-toastify";
import axios from "axios";
import { backendUrl } from "../../constant/BaseUrl";
import { addAboutPageNewPost, addGalleryNewPost, addServicesPageData, addUpcomingEventPost, deleteSpecificContactUs, deleteSpecificServiceDetails, editAboutPageNewPost, editGalleryNewPost, editUpcomingEventPost, getAdminContactUs, getContactUsData, getMisVisNewData, getServicesDetailsNewData, getTitles, getUpcomingEventData, updateTitles } from "../../environment/Api";
import { ErrorMessage, Form, Formik } from "formik";
import Schema from "../../services/ValidationServices";

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/bookedslot" onClick={() => { }}>Dashboard</Link>,
    <Typography key="3" color="text.primary">Contact Us</Typography>,
];

const ContactUs = (props) => {

    const [data, setData] = useState([]);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => { setDeleteOpen(false); setDeleteItem({}) }
    const params = useParams();

    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        await getAdminContactUs({ token: localStorage.getItem('auth-token') }).then((res) => {
            // if (res) {
            setData(res);
            // }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleDelete = () => {
        deleteSpecificContactUs(deleteItem?._id).then((res) => {
            getData();
            toast.success('Delete Successfully.');
            handleDeleteClose();
        })
    }

    return (
        <>
            <DashboardLayout text='CMS'>
                <div className='new-d-flex new-teke'>
                    <BreadCum breadcrumbs={breadcrumbs} />
                </div>
                <div className="page-start">
                    {/* <div className="whowe-title">
                        <Grid container spacing={1} className="align-bottom">
                            <Grid item xs={12} md={6} sm={6}>
                                <div className="subtitile">
                                    <h3>Title</h3>
                                    <input type="text" placeholder="Explore Services" value={titles?.contact_us_title} onChange={(e) => { setTitles({ ...titles, contact_us_title: e.target.value }) }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5} sm={5}>
                                <div className="subtitile">
                                    <h3>Sub Title</h3>
                                    <input type="text" placeholder="The Experience" value={titles?.contact_us_subtitle} onChange={(e) => { setTitles({ ...titles, contact_us_subtitle: e.target.value }) }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={1} sm={1}>
                                <button className='comman-btn' style={{ marginLeft: '0.5rem' }} onClick={() => { handleUpdate(); }}>Update</button>
                            </Grid>
                        </Grid>
                    </div> */}
                    <div className="nae-class-all ">
                        <div className="about-list new-why">
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-new-title">Name</TableCell>
                                            <TableCell className="table-new-title" align="left">Phone</TableCell>
                                            <TableCell className="table-new-title" align="left">Email</TableCell>
                                            <TableCell className="table-new-title" align="left">Subject</TableCell>
                                            <TableCell className="table-new-title" align="left">Message</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data && data.length > 0 && data.map((item, index) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" className="nuber-faq">
                                                        <div className="faq-q-a">
                                                            <p>{item.fullName}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="faq-q-a">
                                                            <p>{item.mobile}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="faq-q-a">
                                                            <p>{item.email}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="faq-q-a">
                                                            <p>{item.subject}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div className="faq-q-a">
                                                            <p>{item.message}</p>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="center" className="edit-delet">
                                                        <div className="add-info">
                                                            {/* <NavLink to={`/cms/contact-us/contactus-edit/${item._id}`}>
                                                                <img src={assetImages.editPenDark} alt="" />
                                                            </NavLink> */}
                                                            <img src={assetImages.newdelete1} style={{ paddingLeft: '1.5rem', cursor: 'pointer' }} alt="" onClick={() => { handleDeleteOpen(); setDeleteItem(item) }} />
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
            <div className="delete-popup">
                <Modal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-potion">
                        <div className="modal-hedding">
                            <h3>&nbsp;&nbsp;Are you sure you want to Delete?</h3>
                            <div className="close-faq" onClick={handleDeleteClose}>
                                <button ><img src={assetImages.closebtn} alt="close" /></button>
                            </div>
                        </div>
                        <div className="modal-delete">
                            <div className="can-de">
                                <button onClick={handleDeleteClose} className="cancel">Cancel</button>
                                <button onClick={handleDelete} className="delete">Delete</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>

        </>
    )
}

export default ContactUs;