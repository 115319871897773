import { CircularProgress } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import DashboardLayout from "../../base/DashboardLayout";
import { addContactUSPost, editContactUSPost } from "../../environment/Api";
import { apiRequest } from "../../environment/Api/apiCall";
import { apipaths } from "../../environment/Api/apiPath";
import Schema from "../../services/ValidationServices";
import assetImages from '../../constant/enum';
import { Navigate, NavLink, useNavigate } from "react-router-dom";

const ContactUsAdd = () => {
    const [selectedImages, setSelectedImages] = useState("");
    const [files, setFiles] = useState([]);
    const [isUploding, setUploding] = useState(false);
    const [error, setError] = useState({});
    const [editEvent, setEditEvent] = useState({});
    const navigate = useNavigate();
    const uploadFileHandler = (event) => {
        setSelectedImages(event.target.files);
    };

    const fileSubmitHandler = async () => {
        const formData = new FormData();
        setUploding(true);
        for (let i = 0; i < selectedImages.length; i++) {
            formData.append("file", selectedImages[i]);
            setUploding(true);
        }
        const response = await apiRequest(apipaths.fileUpload, formData);
        if (response?.statusCode === 200) {
            let totalImages = [];
            if (Array.isArray(response.data) === false) {
                totalImages.push(response.data);
            } else {
                totalImages = totalImages.concat(response.data);
            }
            setFiles(totalImages);
            setUploding(false);
            setSelectedImages("");
            toast.success("File Upload successFully");
        } else {
            setUploding(false);
            console.warn("something wrong while uploading");
        }
    };

    const ContactUs = async (values) => {
        if (files.length == 0) {
            setError({ image: "Please Select Image." })
            return
        }
        await addContactUSPost({
            location: values.location,
            address: values.address,
            link: values.link,
            image: files[0].original,
        }).then((res) => {
            toast.success('Add Successfully.');
            navigate('/cms/contact-us')
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <>
            <DashboardLayout text='Add Address'>
                <div className="faq-form">
                    <Formik
                        initialValues={{
                            location: Object.keys(editEvent).length > 0 ? editEvent.location : "",
                            address: Object.keys(editEvent).length > 0 ? editEvent.address : "",
                            link: Object.keys(editEvent).length > 0 ? editEvent?.link : "",
                        }}
                        validationSchema={Schema.addContactAddress}
                        onSubmit={(values) => {
                            ContactUs(values);
                        }}
                    >
                        {({ errors, values, touched, setFieldValue }) => (
                            <Form>
                                <div className="faq-input-form">
                                    <div className="new-faq">
                                        <label>Name</label>
                                        <input type="text" placeholder="Enter Location" id="location" name="location"
                                            value={values.location}
                                            className={
                                                "form-control" +
                                                (errors.location && touched.location
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={(e) =>
                                                setFieldValue("location", e.target.value)
                                            }
                                        />
                                        <ErrorMessage
                                            name="location"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="new-faq">
                                        <label>Email</label>
                                        <input type="text" placeholder="Enter link" id="link" name="link"
                                            value={values.link}
                                            className={
                                                "form-control" +
                                                (errors.link && touched.link
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={(e) =>
                                                setFieldValue("link", e.target.value)
                                            }
                                        />
                                        <ErrorMessage
                                            name="link"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="new-faq">
                                        <label>Phone</label>
                                        <input type="number" placeholder="Enter link" id="link" name="link"
                                            value={values.link}
                                            className={
                                                "form-control" +
                                                (errors.link && touched.link
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={(e) =>
                                                setFieldValue("link", e.target.value)
                                            }
                                        />
                                        <ErrorMessage
                                            name="link"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="new-faq">
                                        <label>Subject</label>
                                        <input type="number" placeholder="Enter link" id="link" name="link"
                                            value={values.link}
                                            className={
                                                "form-control" +
                                                (errors.link && touched.link
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={(e) =>
                                                setFieldValue("link", e.target.value)
                                            }
                                        />
                                        <ErrorMessage
                                            name="link"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="new-faq">
                                        <label>Address </label>
                                        <textarea type="text" placeholder="Enter Address" id="address" name="address"
                                            value={values.address}
                                            className={
                                                "form-control" +
                                                (errors.address && touched.address
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            onChange={(e) =>
                                                setFieldValue("address", e.target.value)
                                            }
                                        />
                                        <ErrorMessage
                                            name="address"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    <div className="faq-submit">
                                        <NavLink to="/cms/contact-us">
                                            <button style={{ marginLeft: '1rem', marginRight: '1rem' }}>Back</button>
                                        </NavLink>
                                        <button>{Object.keys(editEvent).length > 0 ? 'Update' : 'Add'}</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </DashboardLayout>
        </>
    )
}

export default ContactUsAdd;
