import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import Schema from "../services/ValidationServices";
import { ScaleLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import { apiRequest } from "../environment/Api/apiCall";
import { apipaths } from "../environment/Api/apiPath";
import { toast } from "react-toastify";
import assetImages from "../constant/enum";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [IsRevealPassword, setIsRevealPassword] = useState(false);
  const [IsRevealPassword1, setIsRevealPassword1] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const changedPass = async (values) => {
    setLoading(true);
    const bodyData = {
      email: location?.state?.email,
      code: location?.state?.code,
      password: values?.password,
    };
    const response = await apiRequest(apipaths.resetPass, bodyData);
    if (response?.statusCode === 200) {
      toast.success('Password has been changes Successfully..');
      setLoading(false);
      navigate("/");
    } else {
      setLoading(false);
      navigate("/forgot-password");
      console.warn("something went wrong. Go Back To Home Page!!!..");
    }
  };
  // ChecK the Codition Whether Email Id not get it From User Side Direct Not Hit the url
  useEffect(() => {
    if (
      location?.state?.email === undefined &&
      location?.state?.code === undefined
    ) {
      navigate("/forgot-password");
    }
  }, []);
  return (
    <>
    <div className="login-page-main-area">
     <div className="inner-box-login">
       <div className="left-side-vector-area">
         <img src={assetImages.loginVector} alt="img" />
       </div>
       <div className="right-side-area-main">
         <div className="inner-box-login-right-side">
           <div className="logo-row">
             <img src={assetImages.logo} alt="img" />
           </div>
           <div className="wel-text">Reset Password</div>
           <Formik
            initialValues={{
              password: "",
              confirm_password: "",
            }}
            validationSchema={Schema.resetPassword}
            onSubmit={(values) => {
              changedPass(values);
            }}
          >
            {({ errors, values, touched, setFieldValue }) => (
              <Form>
                <div className="login-form-main">
                  <div className="form-field">
                    <div className="labal-main">New Password</div>
                    <div className="inner-row-main">
                      <input
                        name="password"
                        type={IsRevealPassword ? "text" : "password"}
                        placeholder="Enter a new password"
                        values={values.password}
                        className={
                          "form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          setFieldValue("password", e.target.value)
                        }
                      />
                      <i
                        className={
                          IsRevealPassword
                            ? "fa-solid fa-eye"
                            : "fa-solid fa-eye-slash"
                        }
                        onClick={() =>
                          setIsRevealPassword((prevState) => !prevState)
                        }
                      ></i>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="form-field">
                    <div className="labal-main">Confirm Password</div>
                    <div className="inner-row-main">
                      <input
                        name="confirm_password"
                        type={IsRevealPassword1 ? "text" : "password"}
                        placeholder="Enter a confirm password"
                        className={
                          "form-control" +
                          (errors.confirm_password && touched.confirm_password
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          setFieldValue("confirm_password", e.target.value)
                        }
                      />
                      <i
                        className={
                          IsRevealPassword1
                            ? "fa-solid fa-eye"
                            : "fa-solid fa-eye-slash"
                        }
                        onClick={() =>
                          setIsRevealPassword1((prevState) => !prevState)
                        }
                      ></i>
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="btn-row">
                    <button className="comman-btn" type="submit">
                      {loading ? (
                        <ScaleLoader color={"#fff"} height={15} width={2} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
         </div>
       </div>
     </div>
   </div>
    </>
  );
};

export default ResetPassword;
