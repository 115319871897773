import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../ExternalPages/Login';
import PublicRoutes from '../Routing/PublicRoutes';
import PrivateRoute from '../Routing/PrivateRoute'
import Dashboard from '../InternalPages/Dashboard';
import ForgotPassword from '../ExternalPages/ForgotPassword';
import Otp from '../ExternalPages/Otp';
import ResetPassword from '../ExternalPages/ResetPassword';
import NotFound from '../InternalPages/NotFound';
import SignUp from '../ExternalPages/SignUp';
// import ProductListing from '../InternalPages/ProductListing';
import UserSettings from '../InternalPages/UserSettings';
import ContactUs from '../components/ContactUs/ContactUs';
import ContactUsAdd from '../components/ContactUs/ContactUsAdd';
import ContactUsEdit from '../components/ContactUs/ContactUsEdit';
// import CmsMain from '../InternalPages/CmsMain';
// import Inquiry from '../InternalPages/Inquiry';
// import ProductForm from '../forms/ProductForm/ProductForm';
// import InquriryDetais from '../components/InquiryDetails/InquriryDetais';
// import Blog from '../InternalPages/Blog';
// import EmptyProduct from '../components/Product/EmptyProduct';
// import Faq from '../InternalPages/Faq';
// import BlogEdit from '../components/Blog/BlogEdit';
// import TermsAndConditions from '../InternalPages/TermsAndConditions';
// import TermsAndConditionsAdd from '../components/TermsAndConditions/TermsAndConditionsAdd';
// import AboutUs from '../InternalPages/AboutUs';
// import AboutUsEdit from '../components/AboutUS/AboutUsEdit';
// import HomePage from '../InternalPages/HomePage';
// import Banner from '../components/HomePage/Banner';
// import WhoWeAre from '../components/HomePage/WhoWeAre';
// import OurLuxuryCollection from '../components/HomePage/OurLuxuryCollection';
// import OfferCollection from '../components/HomePage/OfferCollection';
// import BrandInnerPage from '../components/HomePage/BrandInnerPage';
// import BrandImageType from '../components/HomePage/BrandImageType';
// import WeDoourBest from '../components/HomePage/WeDoourBest';
// import Testimonials from '../components/HomePage/Testimonials';
// import Video from '../components/HomePage/Video';
// import MegaMenu from '../InternalPages/MegaMenu';
// import MegaMenuSection from '../components/MegaMenu/MegaMenuSection';
// import ShopByStyle from '../components/MegaMenu/ShopByStyle';
// import Services from '../InternalPages/Services';
// import AboutUsPage from '../InternalPages/AboutUsPage';
// import WhyChooseUs from '../components/AboutUsPage/WhyChooseUs';
// import NetWork from '../components/Network/Network';
// import VideoNew from '../components/Video/VideoNew';
// import TestimonialsHome from '../components/Testimonials/TestimonialsHome';
// import Gallery from '../components/Gallery/Gallery';
// import ServicesNew from '../components/Services/ServicesNew';
// import BannerAdd from '../components/HomePage/BannerAE/BannerAdd';
// import BannerEdit from '../components/HomePage/BannerAE/BannerEdit';
// import ExploreServiceAdd from '../components/HomePage/ExploreService/ExploreServiceAdd';
// import ExploreServiceEdit from '../components/HomePage/ExploreService/ExploreServiceEdit';
// import AtithiEventsAdd from '../components/HomePage/AtithiEvents/AtithiEventsAdd';
// import AtithiEventsEdit from '../components/HomePage/AtithiEvents/AtithiEventsEdit';
// import AnchorForamAdd from '../components/HomePage/AnchorForam/AnchorForamAdd';
// import AnchorForamEdit from '../components/HomePage/AnchorForam/AnchorForamEdit';
// import OurNetWorkAdd from '../components/HomePage/OurNetWork/OurNetWorkAdd';
// import OurNetWorkEdit from '../components/HomePage/OurNetWork/OurNetWorkEdit';
// import VideoAdd from '../components/HomePage/VideoSection/VideoAdd';
// import AddGallery from '../components/HomePage/Gallery/AddGallery';
// import EditGallery from '../components/HomePage/Gallery/EditGallery';
// import VideoEdit from '../components/HomePage/VideoSection/VideoEdit';
// import TestimonialsAdd from '../components/HomePage/Testimonials/TestimonialsAdd';
// import TestimonialsEdit from '../components/HomePage/Testimonials/TestimonialsEdit';
// import AtithiEventsNewAdd from '../components/AboutUS/AtithiEventsNewAdd';
// import AtithiEventsNewEdit from '../components/AboutUS/AtithiEventsNewEdit';
// import VisionAndMissionAdd from '../components/VisionAndMission/VisionAndMissionAdd';
// import VisionAndMissionEdit from '../components/VisionAndMission/VisionAndMissionEdit';
// import ServicesNewAdd from '../components/Services/ServicesNewAdd';
// import ServicesNewEdit from '../components/Services/ServicesNewEdit';
// import UpcomingEvents from '../components/UpcomingEvents/UpcomingEvents';
// import UpcomingEventsAdd from '../components/UpcomingEvents/UpcomingEventsAdd';
// import UpcomingEventsEdit from '../components/UpcomingEvents/UpcomingEventsEdit';
// import ContactUs from '../components/ContactUs/ContactUs';
// import ContactUsAdd from '../components/ContactUs/ContactUsAdd';
// import ContactUsEdit from '../components/ContactUs/ContactUsEdit';
// import HeaderMenu from '../components/header/HeaderMenu';
// import HeaderSubMenu from '../components/header/HeaderSubMenu';
// import OurSkills from '../components/HomePage/OurSkills/OurSkills';
// import OurSkillsAdd from '../components/HomePage/OurSkills/OurSkillsAdd';
// import OurSkillsEdit from '../components/HomePage/OurSkills/OurSkillsEdit';
// import AdminContact from '../components/HomePage/AdminContact/AdminContact';
// import AdminContactAdd from '../components/HomePage/AdminContact/AdminContactAdd';
// import AdminContactEdit from '../components/HomePage/AdminContact/AdminContactEdit';
// import InquiresNew from '../components/Inquiry/InquiryNew';
// import Footer from '../InternalPages/Footer';
// import FooterTop from '../components/Footer/FooterTop';
// import FooterTopAdd from '../components/Footer/FooterTopAdd';
// import FooterBottom from '../components/Footer/FooterBottom';
// import FooterBottomAdd from '../components/Footer/FooterBottomAdd';
// import FooterBottomEdit from '../components/Footer/FooterBottomEdit';
// import ServiceDetails from '../components/HomePage/ServiceDetailsAE/ServiceDetails';
// import ServiceDetailsAdd from '../components/HomePage/ServiceDetailsAE/ServiceDetailsAdd';
// import ServiceDetailsEdit from '../components/HomePage/ServiceDetailsAE/ServiceDetailsEdit';
// import CustomerListing from '../components/Customer/CustomerListing';
// import AddCustomer from '../components/Customer/AddCustomer';
// import SpecificCustomer from '../components/Customer/SpecificCustomer';
// import VendersList from '../components/Vender/VendersList';
// import AddVender from '../components/Vender/AddVender';
// import SpecificVender from '../components/Vender/SpecificVender';
// import EventsList from '../components/Event/EventsList';
// import EventDetail from '../components/Event/EventDetail';
// import EditEventDetail from '../components/Event/EditEventDetail';
// import AddEvent from '../components/Event/AddEvent';
// import AdminServiceList from '../components/AdminService/AdminServiceList';
// import EditCustomer from '../components/Customer/EditCustomer';
// import EditVender from '../components/Vender/EditVender';
// import Notifications from '../components/Notifications/Notifications';
// import Staff from '../components/Staff/Staff';
// import StaffAdd from '../components/Staff/StaffAdd';
// import StaffEdit from '../components/Staff/StaffEdit';
// import Task from '../components/Task/Task';
// import TaskAdd from '../components/Task/TaskAdd';
// import TaskEdit from '../components/Task/TaskEdit';
// import Report from '../components/Report/Report';
// import FarmListing from '../components/Farm/FarmListing';
// import AddFarm from '../components/Farm/AddFarm';
// import EditFarm from '../components/Farm/EditFarm';
// import SpecificFarm from '../components/Farm/SpecificFarm';

import BookedSlot from '../components/BookedSlot/BookedSlot';
import CreateMeeting from '../components/CreateMeeting/CreateMeeting';
import AddSlot from '../components/CreateMeeting/AddSlot';
import EditSlot from '../components/CreateMeeting/EditSlot';

const Layout = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<PublicRoutes component={<Login />} />} />
        <Route path='/signup' element={<PublicRoutes component={<SignUp />} />} />
        <Route path='/forgot-password' element={<PublicRoutes component={<ForgotPassword />} />} />
        <Route path='/otp-verification' element={<Otp />} />
        <Route path='/reset-password' element={<PublicRoutes component={<ResetPassword />} />} />
        {/* <Route path='/dashboard' element={<PrivateRoute component={<Dashboard />} />} />

        <Route path='/product/listing' element={<PrivateRoute component={<ProductListing />} />} />
        <Route path='/empty-product' element={<PrivateRoute component={<EmptyProduct />} />} />
        <Route path='/inquires' element={<PrivateRoute component={<Inquiry />} />} />
        <Route path='/inquiry/:id' element={<PrivateRoute component={<InquriryDetais />} />} /> */}
        {/* <Route path='/cms' element={<PrivateRoute component={<CmsMain />} />} /> */}
        {/* <Route path='/product/form' element={<PrivateRoute component={<ProductForm />} />} />
        <Route path='/cms/services' element={<PrivateRoute component={<Blog />} />} />
        <Route path='/add/services' element={<PrivateRoute component={<Blog />} />} />
        <Route path='/blog/blog-add' element={<PrivateRoute component={<BlogEdit />} />} />
        <Route path='/cms/events' element={<PrivateRoute component={<Faq />} />} />
        <Route path='/cms/terms-and-conditions' element={<PrivateRoute component={<TermsAndConditions />} />} />
        <Route path='/terms-and-conditions/terms-and-conditions-add' element={<PrivateRoute component={<TermsAndConditionsAdd />} />} />
        <Route path='/cms/about-us' element={<PrivateRoute component={<AboutUs />} />} />
        <Route path='/about-us/about-us-add' element={<PrivateRoute component={<AboutUsEdit />} />} />
        <Route path='/cms/home' element={<PrivateRoute component={<HomePage />} />} />
        <Route path='/cms/home/banner' element={<PrivateRoute component={<Banner />} />} />
        <Route path='/cms/home/who-we-are' element={<PrivateRoute component={<WhoWeAre />} />} />
        <Route path='/cms/home/our-luxury-collection' element={<PrivateRoute component={<OurLuxuryCollection />} />} />
        <Route path='/cms/home/offer-collection' element={<PrivateRoute component={<OfferCollection />} />} />
        <Route path='/cms/home/brand-banner/brand-inner/:id' element={<PrivateRoute component={<BrandInnerPage />} />} />
        <Route path='/cms/home/brand-banner/brand-inner/image-type/:id' element={<PrivateRoute component={<BrandImageType />} />} />
        <Route path='/cms/home/We-do-our-best' element={<PrivateRoute component={<WeDoourBest />} />} />
        <Route path='/cms/home/testimonials' element={<PrivateRoute component={<Testimonials />} />} />
        <Route path='/cms/home/video' element={<PrivateRoute component={<Video />} />} />
        <Route path='/cms/megamenu' element={<PrivateRoute component={<MegaMenu />} />} />
        <Route path='/cms/megamenu/category' element={<PrivateRoute component={<MegaMenuSection />} />} />
        <Route path='/cms/megamenu/category/shop-by-style' element={<PrivateRoute component={<ShopByStyle />} />} />
        <Route path='/cms/services-list' element={<PrivateRoute component={<Services />} />} />
        <Route path='/cms/about-us-page' element={<PrivateRoute component={<AboutUsPage />} />} />
        <Route path='/cms/about-us-page/why-choose-us' element={<PrivateRoute component={<WhyChooseUs />} />} />
        <Route path='/cms/our-network' element={<PrivateRoute component={<NetWork />} />} />
        <Route path='/cms/video' element={<PrivateRoute component={<VideoNew />} />} />
        <Route path='/cms/testimonials-home' element={<PrivateRoute component={<TestimonialsHome />} />} />
        <Route path='/cms/gallery' element={<PrivateRoute component={<Gallery />} />} />
        <Route path='/cms/services/services-details/:type' element={<PrivateRoute component={<ServicesNew />} />} /> */}
        {/* 
        <Route path='/cms/banner/banner-add' element={<PrivateRoute component={<BannerAdd />} />} />
        <Route path='/cms/banner/banner-edit/:id' element={<PrivateRoute component={<BannerEdit />} />} />
        <Route path='/cms/services/services-add' element={<PrivateRoute component={<ExploreServiceAdd />} />} />
        <Route path='/cms/services/services-edit/:id' element={<PrivateRoute component={<ExploreServiceEdit />} />} />
        <Route path='/cms/event/atithievent-add' element={<PrivateRoute component={<AtithiEventsAdd />} />} />
        <Route path='/cms/event/atithievent-edit/:id' element={<PrivateRoute component={<AtithiEventsEdit />} />} />
        <Route path='/cms/about-us/anchorforam-add' element={<PrivateRoute component={<AnchorForamAdd />} />} />
        <Route path='/cms/about-us/anchorforam-edit/:id' element={<PrivateRoute component={<AnchorForamEdit />} />} />
        <Route path='/cms/our-network/our-network-add' element={<PrivateRoute component={<OurNetWorkAdd />} />} />
        <Route path='/cms/our-network/our-network-edit/:id' element={<PrivateRoute component={<OurNetWorkEdit />} />} />
        <Route path='/cms/video/video-add' element={<PrivateRoute component={<VideoAdd />} />} />
        <Route path='/cms/video/video-edit/:id' element={<PrivateRoute component={<VideoEdit />} />} />
        <Route path="/cms/home/add-gallery" element={<PrivateRoute component={<AddGallery />} />} />
        <Route path="/cms/home/edit-gallery/:id" element={<PrivateRoute component={<EditGallery />} />} />
        <Route path="/cms/testimonials-home/testimonials-add" element={<PrivateRoute component={<TestimonialsAdd />} />} />
        <Route path="/cms/testimonials-home/testimonials-edit/:id" element={<PrivateRoute component={<TestimonialsEdit />} />} />
        <Route path="/cms/home/who-we-are/atithievent-add" element={<PrivateRoute component={<AtithiEventsNewAdd />} />} />
        <Route path="/cms/home/who-we-are/atithievent-edit/:id" element={<PrivateRoute component={<AtithiEventsNewEdit />} />} />
        <Route path="/cms/about-us-page/vision-mission/vision-mission-add" element={<PrivateRoute component={<VisionAndMissionAdd />} />} />
        <Route path="/cms/about-us-page/vision-mission/vision-mission-edit/:id" element={<PrivateRoute component={<VisionAndMissionEdit />} />} />
        <Route path="/services/services-details/services-add/:type" element={<PrivateRoute component={<ServicesNewAdd />} />} />
        <Route path="/services/services-details/services-edit/:id" element={<PrivateRoute component={<ServicesNewEdit />} />} />
        <Route path="/cms/upcoming-event" element={<PrivateRoute component={<UpcomingEvents />} />} />
        <Route path="/cms/upcoming-event/upcoming-event-add" element={<PrivateRoute component={<UpcomingEventsAdd />} />} />
        <Route path="/cms/upcoming-event/upcoming-event-edit/:id" element={<PrivateRoute component={<UpcomingEventsEdit />} />} />
        <Route path="/cms/contact-us" element={<PrivateRoute component={<ContactUs />} />} />
        <Route path="/cms/contact-us/contactus-add" element={<PrivateRoute component={<ContactUsAdd />} />} />
        <Route path="/cms/contact-us/contactus-edit/:id" element={<PrivateRoute component={<ContactUsEdit />} />} />
        <Route path="/cms/header-menu" element={<PrivateRoute component={<HeaderMenu />} />} />
        <Route path="/cms/header-menu/header-sub-menu/:name/:id" element={<PrivateRoute component={<HeaderSubMenu />} />} />
        <Route path="/cms/home/our-skills" element={<PrivateRoute component={<OurSkills />} />} />
        <Route path="/cms/home/our-skills/add-our-skills" element={<PrivateRoute component={<OurSkillsAdd />} />} />
        <Route path="/cms/home/our-skills/edit-our-skills/:id" element={<PrivateRoute component={<OurSkillsEdit />} />} />
        <Route path="/cms/home/admincontct" element={<PrivateRoute component={<AdminContact />} />} />
        <Route path="/cms/home/admincontct/add-admincontect" element={<PrivateRoute component={<AdminContactAdd />} />} />
        <Route path="/cms/home/our-skills/edit-admincontect/:id" element={<PrivateRoute component={<AdminContactEdit />} />} />
        <Route path="/inquiry-new" element={<PrivateRoute component={< InquiresNew />} />} /> */}

        {/* <Route path="/cms/footer-link" element={<PrivateRoute component={<Footer />} />} />
        <Route path="/cms/footer-link/footer-top" element={<PrivateRoute component={<FooterTop />} />} />
        <Route path="/cms/footer-link/footer-top/footer-top-add/:id" element={<PrivateRoute component={<FooterTopAdd />} />} />
        <Route path="/cms/footer-link/footer-bottom" element={<PrivateRoute component={<FooterBottom />} />} />
        <Route path="/cms/footer-link/footer-bottom/footer-bottom-add" element={<PrivateRoute component={<FooterBottomAdd />} />} />
        <Route path="/cms/footer-link/footer-bottom/footer-bottom-edit/:id" element={<PrivateRoute component={<FooterBottomEdit />} />} />


        <Route path='/cms/home/serviceall' element={<PrivateRoute component={<ServiceDetails />} />} />
        <Route path='/cms/home/serviceall/serviceall-add' element={<PrivateRoute component={<ServiceDetailsAdd />} />} />
        <Route path='/cms/home/serviceall/serviceall-edit/:id' element={<PrivateRoute component={<ServiceDetailsEdit />} />} /> */}

        {/* software */}
        {/* <Route path='/farms' element={<PrivateRoute component={<FarmListing />} />} />
        <Route path='/add-farm' element={<PrivateRoute component={<AddFarm />} />} />
        <Route path='/edit-farm/:id' element={<PrivateRoute component={<EditFarm />} />} />
        <Route path='/farm/:id' element={<PrivateRoute component={<SpecificFarm />} />} />

        <Route path='/customers' element={<PrivateRoute component={<CustomerListing />} />} />
        <Route path='/add-customer' element={<PrivateRoute component={<AddCustomer />} />} />
        <Route path='/edit-customer/:id' element={<PrivateRoute component={<EditCustomer />} />} />
        <Route path='/customers/:id' element={<PrivateRoute component={<SpecificCustomer />} />} />

        <Route path="/venders" element={<PrivateRoute component={<VendersList />} />} />
        <Route path='/add-vender' element={<PrivateRoute component={<AddVender />} />} />
        <Route path='/edit-vender/:id' element={<PrivateRoute component={<EditVender />} />} />
        <Route path='/vender/:id' element={<PrivateRoute component={<SpecificVender />} />} />

        <Route path="/events" element={<PrivateRoute component={<EventsList />} />} />
        <Route path="/add-event" element={<PrivateRoute component={<AddEvent />} />} />
        <Route path="/event/:id" element={<PrivateRoute component={<EditEventDetail />} />} />
        <Route path="/event-details/:id" element={<PrivateRoute component={<EventDetail />} />} />

        <Route path="/admin-services" element={<PrivateRoute component={<AdminServiceList />} />} />
        <Route path="/notifications" element={<PrivateRoute component={<Notifications />} />} />

        <Route path="/staff-list" element={<PrivateRoute component={<Staff />} />} />
        <Route path="/staff-add" element={<PrivateRoute component={<StaffAdd />} />} />
        <Route path="/staff-edit/:id" element={<PrivateRoute component={<StaffEdit />} />} />

        <Route path="/task-list" element={<PrivateRoute component={<Task />} />} />
        <Route path="/task-add" element={<PrivateRoute component={<TaskAdd />} />} />
        <Route path="/task-edit/:id" element={<PrivateRoute component={<TaskEdit />} />} />

        <Route path='/report' element={<PrivateRoute component={<Report />} />} /> */}



        <Route path="/bookedslot" element={<PrivateRoute component={<BookedSlot />} />} />
        <Route path="/createameeting" element={<PrivateRoute component={<CreateMeeting />} />} />
        <Route path="/add-slot" element={<PrivateRoute component={<AddSlot />} />} />
        <Route path="/edit-slot/:id" element={<PrivateRoute component={<EditSlot />} />} />
        <Route path='/setting' element={<PrivateRoute component={<UserSettings />} />} />
        <Route path="/cms/contact-us" element={<PrivateRoute component={<ContactUs />} />} />
        <Route path="/cms/contact-us/contactus-add" element={<PrivateRoute component={<ContactUsAdd />} />} />
        <Route path="/cms/contact-us/contactus-edit/:id" element={<PrivateRoute component={<ContactUsEdit />} />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}

export default Layout;