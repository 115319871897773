import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import Schema from "../services/ValidationServices";
import { ScaleLoader } from "react-spinners";
import { apiRequest } from "../environment/Api/apiCall";
import { apipaths } from "../environment/Api/apiPath";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import assetImages from "../constant/enum";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("auth-token");
  const navigate = useNavigate();
  const EmailVerification = async (values) => {
    setLoading(true);
    const bodyData = {
      email: values?.EmailId,
    };
    const res = await apiRequest(apipaths.forgotPass, bodyData);
    if (res?.statusCode === 200) {
      toast.success(res?.data?.customMessage);
      setLoading(false);
      navigate("/otp-verification", { state: { email: values?.EmailId } });
    } else {
      setLoading(false);
      console.warn("something went wrong. Go Back To Home Page!!!..");
    }
  };

  // If user already logdin then redirect on home page
  useEffect(() => {
    if (authToken) {
      navigate("/");
    }
  }, []);
  return (
    <>
     <div className="login-page-main-area">
     <div className="inner-box-login">
       <div className="left-side-vector-area">
         <img src={assetImages.loginVector} alt="img" />
       </div>
       <div className="right-side-area-main">
         <div className="inner-box-login-right-side">
           <div className="logo-row">
             <img src={assetImages.logo} alt="img" />
           </div>
           <div className="wel-text">Forgot Password</div>
           <Formik
            initialValues={{
              EmailId: "",
            }}
            validationSchema={Schema.forgotData}
            onSubmit={(values) => {
              EmailVerification(values);
            }}
          >
            {({ errors, values, touched, setFieldValue }) => (
              <Form>
                <div className="login-form-main">
                  <div className="form-field">
                    <div className="labal-main">Email Id</div>
                    <div className="inner-row-main">
                      <input
                        name="EmailId"
                        type="text"
                        values={values.EmailId}
                        placeholder="Enter Your Email"
                        className={
                          "form-control" +
                          (errors.EmailId && touched.EmailId
                            ? " is-invalid"
                            : "")
                        }
                        onChange={(e) =>
                          setFieldValue("EmailId", e.target.value)
                        }
                      />
                      <ErrorMessage
                        name="EmailId"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                  <div className="btn-row login-btn">
                    <button className="comman-btn" type="submit">
                      {loading ? (
                        <ScaleLoader color={"#fff"} height={15} width={2} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
         </div>
       </div>
     </div>
   </div>
   </>
  );
};

export default ForgotPassword;
