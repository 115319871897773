import { GET_SIDEBAR, GET_SIDEBAR_ERROR, GET_SIDEBAR_START } from "./SidebarActions"

const intialState = {
    sideBar : [],
    sideBarError: false,
    sideBarLoading:false
}

export const SideBarReducer = (state = intialState, action) => {
    switch(action.type){
        case GET_SIDEBAR_START : 
          return{
                ...state,
                sideBarLoading:true,
            }
        case GET_SIDEBAR :
            return{
                ...state,
                sideBar:action.payload,
                sideBarError: false,
                sideBarLoading:false
            }        
        case GET_SIDEBAR_ERROR:
            return {
                ...state,
                sideBarError: true,
                sideBarLoading: true,
            }
        default:
            return state;
    }
}