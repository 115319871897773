export const apipaths = {
    login: { method: "POST", url: '/admin/login' },
    forgotPass: { method: "POST", url: '/admin/forgot-password' },
    otp: { method: "POST", url: '/admin/verify-otp' },
    resetPass: { method: "POST", url: '/admin/change-password' },
    getSideBar: { method: 'GET', url: '/admin/modules' },
    customers: { method: 'GET', url: '/admin/customers' },
    subAdmins: { method: 'GET', url: '/admin/subadmin' },
    getVendor: { method: 'GET', url: '/admin/vendor' },
    rejectVendor: { method: 'POST', url: '/admin/vendor-reject' },
    // customers: { method: 'GET', url: '/admin/customers' },
    product: { method: 'GET', url: '/admin/product' },
    addSubAdmin: { method: 'POST', url: '/admin/create-subadmin' },
    createOffer: { method: 'POST', url: '/admin/offer' },
    // addSubAdmin: { method: 'POST', url: '/admin/create-subadmin' },
    announceMent: { method: 'GET', url: '/announcement' },
    fileUpload: { method: "POST", url: "/file-upload" },
    deleteFile: { method: "DELETE", url: "/delete-file" },
    addAnnounce: { method: 'POST', url: '/admin/announcement' },
    vendor: { method: 'GET', url: '/admin/vendor' },
    review: { method: 'GET', url: '/admin/review' },
    categoriesMain: { method: 'GET', url: '/categories' },
    addCateogryMain: { method: 'POST', url: '/admin/categories' },
    deleteCategories: { method: 'POST', url: '/admin/categories' },
    subCatMain: { method: 'GET', url: '/subCategories' },
    subCatAdd: { method: 'POST', url: '/admin/subCategories' },
    // deleteCategories: { method: 'POST', url: '/admin/categories' },
    editSubCat: { method: 'POST', url: '/admin/subCategories' },
    SpecifiCat: { method: 'GET', url: '/admin/specific-category' },
    AddSpecific: { method: 'POST', url: '/admin/specific-category' },
    faq: { method: 'GET', url: '/faq' },
    addFaq: { method: 'POST', url: '/admin/faq' },
    deleteFaq: { method: "DELETE", url: "/admin/faq" },
    getBlogs: { method: 'GET', url: '/blog' },
    deleteBlog: { method: 'DELETE', url: '/admin/blog' },
    addBlogs: { method: 'POST', url: '/admin/blog' },
    getTerms: { method: 'GET', url: '/termsAndCondition' },
    addterms: { method: 'POST', url: '/admin/termsAndCondition' },
    deleteTerms: { method: 'DELETE', url: '/admin/termsAndCondition' },
    getAbout: { method: 'GET', url: '/AboutUs' },
    addAbout: { method: 'POST', url: '/admin/AboutUs' },
    deleteAbout: { method: 'DELETE', url: '/admin/AboutUs' },
    getCareer: { method: 'GET', url: '/career' },
    addCarrer: { method: 'POST', url: '/admin/career' },
    deleteCarrer: { method: 'DELETE', url: '/admin/career' },
    deleteFaqQuestion: { method: 'DELETE', url: '/admin/faq/question' },
    banner: { method: "POST", url: "/admin/banner" },
    // deleteBanner: { method: "DELETE", url: "/admin/banner" },
    getBanner: { method: "GET", url: "/banner" },
    dashboardCounter: { method: 'GET', url: '/admin/dashboard/counter' },
    chartData: { method: 'GET', url: '/admin/dashboard/sales' },
    sellerData: { method: 'GET', url: '/admin/dashboard/seller' },
    customerData: { method: 'GET', url: '/admin/dashboard/customers' },
    importCustomerData: { method: 'POST', url: '/admin/import/xlsx' },
    exportCustomerData: { method: 'GET', url: '/admin/export/xlsx' },

    brand: { method: 'POST', url: '/admin/brand' },
    brandNew: { method: 'GET', url: '/admin/brand' },
    addSareeBrand: { method: 'POST', url: '/admin/brand' },
    editSareeBrand: { method: 'POST', url: '/admin/brand' },
    editBrand: { method: 'POST', url: '/admin/brand' },
    deleteBrand: { method: 'DELETE', url: '/admin/brand' },

    addBasicDescription: { method: 'POST', url: '/admin/brand' },
    editBasicDescription: { method: 'POST', url: '/admin/brand/desc' },
    deleteBasicDescription: { method: 'DELETE', url: '/admin/brand/desc' },



    addBanner: { method: 'GET', url: '/banner' },
    addBannerPost: { method: 'POST', url: '/admin/banner' },
    editBannerPost: { method: 'POST', url: '/admin/banner' },
    deleteBanner: { method: 'DELETE', url: '/admin/banner' },

    addAboutData: { method: 'GET', url: '/admin/whoweare' },
    addWhoWeArePost: { method: 'POST', url: '/admin/whoweare' },
    editWhoWeArePost: { method: 'POST', url: '/admin/whoweare' },

    addCollectionData: { method: 'GET', url: '/collection' },
    addCollectionPost: { method: 'POST', url: '/admin/collection' },
    editCollectionPost: { method: 'POST', url: '/admin/collection' },
    deleteCollection: { method: 'DELETE', url: '/admin/collection' },
    getBrand: { method: 'GET', url: '/admin/brand' },
    getBrandById: { method: 'GET', url: '/admin/brand' },
    getCategory: { method: 'GET', url: '/categories' },


    addOfferData: { method: 'GET', url: '/offer' },
    addOfferPost: { method: 'POST', url: '/admin/offer' },
    editOfferPost: { method: 'POST', url: '/admin/offer' },
    deleteOffer: { method: 'DELETE', url: '/admin/offer' },


    addOurBestData: { method: 'GET', url: '/ourbest' },
    addOurBestPost: { method: 'POST', url: '/admin/ourbest' },
    editOurBestPost: { method: 'POST', url: '/admin/ourbest' },
    deleteOurBest: { method: 'DELETE', url: '/admin/ourbest' },

    addVideoData: { method: 'GET', url: '/video' },
    // addVideoPost: { method: 'POST', url: '/admin/video' },
    // editVideoPost: { method: 'POST', url: '/admin/video' },
    deleteVideo: { method: 'DELETE', url: '/admin/video' },

    addTestimonialData: { method: 'GET', url: '/testimonial' },
    addTestimonialPost: { method: 'POST', url: '/admin/testimonial' },
    editTestimonialPost: { method: 'POST', url: '/admin/testimonial' },
    deleteTestimonial: { method: 'DELETE', url: '/admin/testimonial' },


    deletSareeFromBrand: { method: "DELETE", url: '/admin/brand/sarees' },


    addAllProductData: { method: 'GET', url: '/product' },
    addALLProductDataPost: { method: 'POST', url: '/product' },
    editALLProductDataPost: { method: 'POST', url: '/product' },
    deleteALLProductData: { method: 'DELETE', url: '/admin/delete' },

    subCategoryData: { method: 'GET', url: '/subcategories' },

    getServicesData: { method: 'GET', url: '/explore-services' },
    addServicesPost: { method: 'POST', url: '/admin/explore-services' },
    editServicesPost: { method: 'POST', url: '/admin/explore-services' },


    getEventsData: { method: 'GET', url: '/atithi-events' },
    addEventsPost: { method: 'POST', url: '/admin/atithi-events' },
    editEventsPost: { method: 'POST', url: '/admin/atithi-events' },

    getAnchoreData: { method: 'GET', url: '/anchore-foram' },
    addAnchorePost: { method: 'POST', url: '/admin/anchore-foram' },
    editAnchorePost: { method: 'POST', url: '/admin/anchore-foram' },

    getOurNetworkData: { method: 'GET', url: '/ournetwork' },
    addOurNetworkPost: { method: 'POST', url: '/admin/ournetwork' },
    editOurNetworkPost: { method: 'POST', url: '/admin/ournetwork' },

    getVideoData: { method: 'GET', url: '/video' },
    addVideoPost: { method: 'POST', url: '/admin/video' },
    editVideoPost: { method: 'POST', url: '/admin/video' },


    getTestimonialsData: { method: 'GET', url: '/testimonial' },
    addTestimonialsPost: { method: 'POST', url: '/admin/testimonial' },
    editTestimonialsPost: { method: 'POST', url: '/admin/testimonial' },

    getGalleryData: { method: 'GET', url: '/gallery' },
    addGalleryPost: { method: 'POST', url: '/admin/gallery' },
    editGalleryPost: { method: 'POST', url: '/admin/gallery' },

    getAboutPageData: { method: 'GET', url: '/atithiaboutus?skip=0&limit=1' },
    addAboutPagePost: { method: 'POST', url: '/admin/aboutus' },
    editAboutPagePost: { method: 'POST', url: '/admin/aboutus' },

    // getOurMisVisData: { method: 'GET', url: '/aboutus' },
    getOurMisVisData: { method: 'GET', url: '/aboutus?title=Our Vision,Our Mission&skip=0&limit=2' },

    getServicesDetailsData: { method: 'GET', url: '/services-details' },
    deleteSpecificAboutUsPath: { method: "DELETE", url: "/admin/anchore-foram" },
    deleteServiceSpecificPath: { method: "DELETE", url: "/admin/explore-services" },
    deleteSpecificEventPath: { method: "DELETE", url: "/admin/atithi-events" },
    deleteSpecificNetworkPath: { method: "DELETE", url: "/admin/ournetwork" },
    deleteSpecificVideoPath: { method: "DELETE", url: "/admin/video" },
    deleteSpecificTestimonialPath: { method: "DELETE", url: "/admin/testimonial" },
    deleteSpecifiGalleryPath: { method: "DELETE", url: "/admin/gallery" },
    deleteWhoWeAre: { method: 'DELETE', url: '/admin/aboutus' },
    addServicesPageDataPath: { method: 'POST', url: '/admin/services-details' },
    deleteSpecificServiceDetailsPath: { method: 'DELETE', url: '/admin/services-details' },
    getSpecificGalleryPath: { method: 'GET', url: '/gallery' },

    getUpcomingEventNewData: { method: 'GET', url: '/upcoming-events' },
    addUpcomingEventNewPost: { method: 'POST', url: '/admin/upcoming-events' },
    editUpcomingEventNewPost: { method: 'POST', url: '/admin/upcoming-events' },
    getSpeicicBannerPath: { method: 'GET', url: '/banner' },
    getSpecificExporeServicePath: { method: 'GET', url: '/explore-services' },
    getSpecificAtithiEventPath: { method: 'GET', url: '/atithi-events' },
    getSpecificAtithiForamPath: { method: 'GET', url: '/anchore-foram' },
    getSpecificNetworkPath: { method: 'GET', url: '/ournetwork' },

    getContactUsNewData: { method: 'GET', url: '/contactus' },
    addContactUsNewPost: { method: 'POST', url: '/admin/contactus' },
    editContactUsNewPost: { method: 'POST', url: '/admin/contactus' },
    getSpecificVideoPath: { method: 'GET', url: '/video' },
    getSpecificTestimonialPath: { method: 'GET', url: '/testimonial' },
    getSpecificAboutUsDetailsPath: { method: 'GET', url: '/atithiaboutus' },
    getSpecificVisionMissionPath: { method: 'GET', url: '/aboutus' },
    getSpecificServiceDetailsPath: { method: 'GET', url: '/services-details' },
    getSpecificUpcomingEventPath: { method: 'GET', url: '/upcoming-events' },
    deleteSpecificUpcommingEventsPath: { method: 'DELETE', url: '/admin/upcoming-events' },
    getSpecificContactUsPath: { method: 'GET', url: '/contactus' },

    getHeaderNewData: { method: 'GET', url: '/getheader' },
    addHeaderPost: { method: 'POST', url: '/header' },
    deleteHeader: { method: 'DELETE', url: '/admin/header' },

    getHeadersubData: { method: 'GET', url: '/getheader/subheader' },

    getOurbestData: { method: 'GET', url: '/ourbest' },
    addOurbestNewPost: { method: 'POST', url: '/admin/ourbest' },

    getSpicficSkillsData: { method: 'GET', url: '/ourbest' },

    addAdminContctPost: { method: 'POST', url: '/admin/admincontact' },
    getAdminContctPost: { method: 'GET', url: '/admincontact' },

    deleteAdminContact: { method: 'DELETE', url: '/admin/admincontact' },
    deleteSkills: { method: 'DELETE', url: '/admin/ourbest' },

    getContactData: { method: 'GET', url: '/work-contact' },
    getFreeQuateData: { method: 'GET', url: '/getfreequate' },
    deleteFreeQuate: { method: 'DELETE', url: '/admin/getfreequate' },
    deleteWorkContact: { method: 'DELETE', url: '/admin/work-contact' },

    addVissionMission: { method: 'POST', url: '/admin/vission-mission' },
    getVissionMission: { method: 'GET', url: '/vission-mission' },
    deleteVissionMission: { method: 'DELETE', url: '/admin/vission-mission' },


    getAdminNewData: { method: 'GET', url: '/management/admin' },

    getTitle: { method: 'GET', url: "/titles" },
    updateTitle: { method: 'POST', url: "/admin/titles" },

    getFooterTop: { method: 'GET', url: "/footer" },
    addFooterTopData: { method: 'POST', url: "/admin/footer" },


    getFooterMenu: { method: 'GET', url: "/footer-menu" },
    deleteFooterMenu: { method: 'DELETE', url: '/admin/footer-menu' },
    addFootermenu: { method: 'POST', url: '/admin/footer-menu' },
    getServicesNewDataHomePath: { method: 'GET', url: "/homeexplore-services" },
    editServicesPostHomePath: { method: 'POST', url: '/admin/homeexplore-services' },
    deleteServiceSpecificHomePath: { method: 'DELETE', url: '/admin/homeexplore-services' },


    // software
    getAllCustomerDataPath: { method: 'GET', url: '/customer' },
    deleteCustomerPath: { method: 'DELETE', url: '/admin/customer' },
    addEditCustomerPath: { method: 'POST', url: '/admin/customer' },
    addSchedulePath: { method: 'POST', url: '/admin/schedule-meeting' },
    getScheduleDataListPath: { method: 'GET', url: '/schedule-meeting' },

    addEditvenderServicePath: { method: 'POST', url: '/admin/services' },
    getAllSevicesPath: { method: 'GET', url: '/services' },
    deleteServiceVenderPath: { method: 'DELETE', url: '/admin/services' },
    addEditVenderPath: { method: 'POST', url: '/admin/vendor' },
    getAllVendersPath: { method: 'GET', url: '/vendor' },
    deleteVenderPath: { method: 'DELETE', url: '/admin/vendor' },
    addEditEventPath: { method: 'POST', url: '/admin/event-booking' },
    getAllEventsPath: { method: 'GET', url: '/event-booking' },
    getSpeficCustomerDetailsPath: { method: 'GET', url: '/admin/customer/profile' },
    getAllVendersSpecificPath: { method: 'GET', url: '/vendor-profile' },
    getSpecificEventPath: { method: 'GET', url: '/admin/eventDetail' },
    deleteEventBookingPath: { method: 'DELETE', url: '/admin/event/booking' },

    notificationPath: { method: 'GET', url: '/notification' },
    removeNotificationPath: { method: 'POST', url: '/admin/delete/notification' },
    deleteSchedulePath: { method: 'DELETE', url: '/admin/schedule-meeting' },

    getSubAdminPath: { method: 'GET', url: '/admin/management/subadmin' },
    addSubAdminPath: { method: 'POST', url: '/admin/management/create-subadmin' },
    deleteSubAdminPath: { method: 'POST', url: '/admin/management/subadmin' },
    getModulesPath: { method: 'GET', url: '/admin/modules' },
    getTasksPath: { method: 'GET', url: '/admintask' },
    assignTasksPath: { method: 'POST', url: '/admin/task' },
    deleteTaskPath: { method: 'DELETE', url: '/admintask' },
    changeTaskStatus: { method: 'POST', url: '/admintask/status' },
    getVendorReportPath: { method: 'GET', url: '/admin/vendorRecord' },
    getEventReportPath: { method: 'GET', url: '/admin/customerRecord' },
    getOtherExpencePath: { method: 'GET', url: '/admin/otherExpence' },

    addPostClosePath: { method: 'POST', url: '/admin/status' },
    getTaskCountPath: { method: 'GET', url: '/admintask-count' },
    taskOpendPath: { method: 'GET', url: '/admintask-change-view' },

    getMeetingNotificationPath: { method: 'GET', url: '/schedule-meeting-notification' },
    removeNotificationMettingPath: { method: 'POST', url: '/admin/delete/schedule-meeting-notification' },
    getNewNotificationsDataPath: { method: 'GET', url: '/get-new-notifications' },
    setAllOpendNotificationPath: { method: 'GET', url: '/open-schedule-meeting-notification' },

    addEditFarmPath: { method: 'POST', url: '/admin/farm' },
    getFarmPath: { method: 'GET', url: '/farms' },
    deleteFarmPath: { method: 'DELETE', url: '/admin/farm' },
    getSpecificFarmPath: { method: 'GET', url: '/admin/farm/details' },




    getAllSlot: { method: 'POST', url: '/get-sloats' },
    addSloatsPath: { method: 'POST', url: '/admin/create-sloat' },
    deleteSloatPath: { method: 'POST', url: '/delete-sloats' },
    getAdminPath: { method: 'POST', url: '/admin/profile' },
    editProfile: { method: 'POST', url: '/admin/update-profile' },
    getMettingsPath: { method: 'POST', url: '/get-mettings' },
    getAdminContactUsPath: { method: 'POST', url: '/admin/contact-us' },
    deletEvent: { method: 'DELETE', url: '/delete-event' },
    deleteSpecificContactUsPath: { method: 'DELETE', url: '/contact-us' },
    getDatePath: { method: "GET", url: '/get-date' }
}