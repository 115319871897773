import React, { useState } from 'react'
import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import "./style.scss";
import Schema from '../../services/ValidationServices';

import { toast } from 'react-toastify';
import { apiRequest } from '../../environment/Api/apiCall';
import { apipaths } from '../../environment/Api/apiPath';
import { useEffect } from 'react';
import { editProfilePost, getAdmin, getAdminAllData } from '../../environment/Api';


const UserProfile = (props) => {
    const [changePasswordStatus, setChangePasswordStatus] = useState(false);
    const [error, setError] = useState({});
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { gridOneArgs, gridTwoArgs } = props;
    const [profileData, setProfileData] = useState(null);
    const [selectedImages, setSelectedImages] = useState("");
    const [files, setFiles] = useState("");
    const [editProfile, setEditProfile] = useState(true);
    const [IsRevealPassword, setIsRevealPassword] = useState(false);
    const [IsRevealPassword2, setIsRevealPassword2] = useState(false);
    // console.log("profileData", (profileData))
    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        let body = {
            token: localStorage.getItem('auth-token')
        }
        getAdmin(body).then((res) => {
            if (res.status === 200) {
                setProfileData(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    }


    const handleSubmit = (obj) => {
        if (changePasswordStatus) {
            let err = {};
            if (oldPassword === "") {
                err.oldPassword = "Please Enter Old Password.";
            }
            if (password === "") {
                err.password = "Please Enter New Password.";
            }
            if (confirmPassword === "") {
                err.confirmPassword = "Please Enter Confirm Password.";
            } else {
                if (password !== confirmPassword) {
                    err.confirmPassword = "New Password and confirm password is not match.";
                }
            }
            setError(err);

            if (Object.keys(err).length > 0) {
                return;
            } else {
                setError({})
            }
        }
        if (oldPassword) {
            obj.oldPassword = oldPassword;
        }
        if (password) {
            obj.password = password;
        }
        obj.token = localStorage.getItem('auth-token')
        console.log("obj", obj)
        delete obj['passwordConfirmation']
        editProfilePost(obj).then((res) => {
            console.log("sdzcx", res)
            if (res.status === 500 && res.error) {
                toast.error(res.error);
            } else {
                getData();
                toast('Success');
                setEditProfile(true);
                setChangePasswordStatus(false);
            }
        }).catch((err) => {
            console.log("sdzcx", err)
        })
    }



    return (
        <>

            {
                editProfile ?
                    <>
                        <Grid container spacing={1} className="align-bottom">
                            <Grid item xs={12} md={6} sm={6}>
                                <div className='user-profiles'>
                                    <h3>User Profile</h3>
                                    <div className='all-data'>
                                        <p>Full Name: <span>{profileData?.fullName}</span></p>
                                        <p>Email : <span>{profileData?.email}</span></p>
                                        <p>Mobile : <span>{profileData?.mobile}</span></p>
                                    </div>
                                </div>
                                <div className='upadet-btn-top'>
                                    <button className='all-new' onClick={() => { setEditProfile(!editProfile) }}>Update</button>
                                </div>
                            </Grid>

                        </Grid>


                    </>

                    :
                    profileData !== null &&
                    <>
                        <div className='new-hedding-top'>
                            <h3>Update Profile</h3>
                        </div>
                        <Formik
                            initialValues={{
                                fullName: profileData?.fullName,
                                email: profileData?.email,
                                mobile: profileData?.mobile,
                                password: '',
                            }}
                            validationSchema={Schema.newUserData}
                            onSubmit={(values) => {
                                console.log("dfhdfu", values)
                                handleSubmit(values)
                            }}
                        >
                            {({ errors, values, touched, setFieldValue }) => (
                                <Form className='profile-form'>
                                    {console.log("errors", errors)}
                                    <Grid container className='profile-form-grid'>
                                        <Grid item {...gridOneArgs}>
                                            <div className="form-group">
                                                <label htmlFor="">Full Name</label>
                                                <input type="text"
                                                    name="fullName"
                                                    value={values.fullName}
                                                    className={
                                                        "form-control" +
                                                        (errors.fullName && touched.fullName
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue("fullName", e.target.value)
                                                    } />
                                            </div>
                                        </Grid>
                                        <Grid item {...gridOneArgs}>
                                            <div className="form-group">
                                                <label htmlFor="">Email</label>
                                                <input type="text"
                                                    name="email"
                                                    value={values.email}
                                                    disabled
                                                    className={
                                                        "form-control" +
                                                        (errors.email && touched.email
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue("emil", e.target.value)
                                                    } />
                                            </div>
                                        </Grid>
                                        <Grid item {...gridOneArgs}>
                                            <div className="form-group">
                                                <label htmlFor="">Contact Number</label>
                                                <input type="text"
                                                    name="mobile"
                                                    value={values.mobile}
                                                    className={
                                                        "form-control" +
                                                        (errors.mobile && touched.mobile
                                                            ? " is-invalid"
                                                            : "")
                                                    }
                                                    onChange={(e) =>
                                                        setFieldValue("mobile", e.target.value)
                                                    } />
                                            </div>
                                        </Grid>
                                        {
                                            changePasswordStatus === false &&
                                            <Grid item {...gridOneArgs} className="form-action ">
                                                <button className='discard-btn' onClick={
                                                    () => { setChangePasswordStatus(true) }
                                                }>Change Password</button>
                                            </Grid>
                                        }
                                        {
                                            changePasswordStatus && <>
                                                <Grid item {...gridOneArgs}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Old Password</label>
                                                        <input type="password" name="oldpassword"
                                                            value={values.oldpassword}
                                                            className={
                                                                "form-control" +
                                                                (errors.oldpassword && touched.oldpassword
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            onChange={(e) => {
                                                                setOldPassword(e.target.value);
                                                            }} />
                                                        {error && error?.oldPassword && <div className='error'> {error?.oldPassword}</div>}
                                                    </div>
                                                </Grid>
                                                <Grid item {...gridOneArgs}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Password</label>
                                                        <input name="password" type={IsRevealPassword ? "text" : "password"} placeholder='Password'
                                                            value={password}
                                                            className={
                                                                "form-control" +
                                                                (errors.password && touched.password
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }
                                                            } />
                                                        <div className='position-pass'>
                                                            <i className={IsRevealPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                                                                onClick={() => setIsRevealPassword((prevState) => !prevState)}
                                                            ></i>
                                                        </div>
                                                        {error && error?.password && <div className='error'> {error?.password}</div>}

                                                    </div>
                                                </Grid>
                                                <Grid item {...gridOneArgs}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Confirm Password</label>
                                                        <input type={IsRevealPassword2 ? "text" : "password"} placeholder='Confirm Password'
                                                            value={values.passwordConfirmation}
                                                            className={
                                                                "form-control" +
                                                                (errors.passwordConfirmation && touched.passwordConfirmation
                                                                    ? " is-invalid"
                                                                    : "")
                                                            }
                                                            name="passwordConfirmation"
                                                            onChange={(e) => {
                                                                setConfirmPassword(e.target.value);
                                                            }
                                                            } />
                                                        <div className='position-pass'>
                                                            <i className={IsRevealPassword2 ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                                                                onClick={() => setIsRevealPassword2((prevState) => !prevState)}
                                                            ></i>
                                                        </div>
                                                        {error && error?.confirmPassword && <div className='error'> {error?.confirmPassword}</div>}

                                                    </div>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                    <div className="form-action">
                                        <button className='discard-btn' type='button' onClick={() => { setEditProfile(true) }}>Discard</button>
                                        <button type='submit' className='save-btn'>Save</button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </>

            }

        </>
    )
}

export default UserProfile