export const GET_ABOUT_START = 'GET_ABOUT_START'
export const GET_ALL_ABOUT = 'GET_ALL_ABOUT'
export const GET_ABOUT_ERROR = 'GET_ABOUT_ERROR'

export function getAboutStart(){
    return{
        type:GET_ABOUT_START
    }
}

export function getAllAbout(data){
    return{
        type:GET_ALL_ABOUT,
        payload:data
    }
}

export function getAboutError(){
    return{
        type:GET_ABOUT_ERROR
    }
}
