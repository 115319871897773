import { GET_ABOUT_ERROR, GET_ABOUT_START, GET_ALL_ABOUT, } from "./AboutActions"


const intialstate = {
    AllAbout:[],
    AboutLoading:false,
    AboutError:false
}

export const AboutReducer = (state = intialstate, action) =>{
    switch(action.type){
        case GET_ABOUT_START: 
        return{
            ...state,
            AboutLoading:true,
        }
        case GET_ALL_ABOUT:
            return{
                ...state,
               AllAbout:action.payload,
               AboutLoading: false,
               AboutError:false,
            }        
        case GET_ABOUT_ERROR:
            return {
                ...state,
                AboutError: true,
                AboutLoading: true,
            }
        default:
            return state;
    }
}