import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
// import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
import { apiRequest } from "../environment/Api/apiCall";
import { apipaths } from "../environment/Api/apiPath";
import assetImages from "../constant/enum";

const Otp = () => {
  const [code, setCode] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const handleChange = (code) => setCode(code);
  const [loading, setLoading] = useState(false);
  const VeryUser = async () => {
    if (code) {
      setLoading(true);
      const bodyData = {
        code: code,
        email: location?.state?.email,
      };
      const response = await apiRequest(apipaths.otp, bodyData);
      if (response?.statusCode === 200) {
        toast.success(response?.message);
        setLoading(false);
        navigate("/reset-password", {
          state: {
            email: location?.state?.email,
            code: code,
          },
        });
      }
    } else {
      toast.error("Otp is Required");
      console.warn("something wrong..");
      setLoading(false);
    }
  };

  // ChecK the Codition Whether Email Id not get it From User Side
  useEffect(() => {
    if (location?.state?.email === undefined) {
      navigate("/forgot-password");
    }
  }, []);

  return (
    <>

      <div className="login-page-main-area">
        <div className="inner-box-login">
          <div className="left-side-vector-area">
            <img src={assetImages.loginVector} alt="img" />
          </div>
          <div className="right-side-area-main">
            <div className="inner-box-login-right-side">
              <div className="logo-row">
                <img src={assetImages.logo} alt="img" />
              </div>
              <Box className="forgot-password-main sign-up-page otp-page-main">
                <div className="modal-body">
                  <div className="login-modal-main">
                    <div className="email-id-recived-otp">{location?.state?.email}</div>
                    <div className="enter-otp">Enter the OTP you received to</div>
                    <div className="formfield-area-main">
                      {/* <OtpInput
                value={code}
                onChange={handleChange}
                numInputs={6}
                separator={<span style={{ width: "10px" }}></span>}
                isInputNum={true}
                shouldAutoFocus={true}
                inputStyle={{
                  background: "#e9e9e9",
                  borderRadius: "8px",
                  width: "60px",
                  height: "60px",
                  fontSize: "30px",
                  color: "#000",
                  fontWeight: "400",
                  caretColor: "blue",
                  border: "none",
                }}
                focusStyle={{
                  border: "1px solid #CFD3DB",
                  outline: "none",
                }}
              /> */}
                    </div>
                    <div className="btn-row" style={{ marginTop: "30px" }}>
                      <button className="comman-btn" onClick={VeryUser} type="submit">
                        {loading ? (
                          <ScaleLoader color={"#fff"} height={15} width={2} />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
