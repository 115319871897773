import logo from '../assets/images/logo.png'
import menu from '../assets/images/menu-icon.svg'
import dashbicon from '../assets/images/element-2.png'
import listing from '../assets/images/task-square.png'
import user01 from '../assets/images/user01.png'
import logout01 from '../assets/images/logout01.png'
import linkurl from '../assets/images/linkurl.png'
import notped from '../assets/images/not-ped.png'
import detelenew from '../assets/images/delet-new.png'
import pluseadd from '../assets/images/pluse-add.png'
import cridelete from '../assets/images/cri-delete.png'




import bag from '../assets/images/bag-tick.svg'
import product from '../assets/images/product.svg'
import money from '../assets/images/money.svg'
import location from '../assets/images/location.svg'
import message from '../assets/images/message-text.svg'
import groupUser from '../assets/images/group-of-people.svg'
import profile from '../assets/images/profile.svg'
import loginVector from '../assets/images/login-vector.svg'
import blackLogo from '../assets/images/black-logo.svg'
import downloadIcon from '../assets/images/download-icon.svg'
import girlImg from '../assets/images/girl-img.jpg'
import EditIcon from '../assets/images/edit-icon.svg'
import producView from '../assets/images/product-view.svg'
import commingSoon from '../assets/images/comming-soon-img.jpg'
import fileUpload from '../assets/images/file-upload.svg'
import productImg from '../assets/images/product-img.png'
import filterIcon from '../assets/images/filterIcon.png'
import columnIcon from '../assets/images/columnIcon.svg'
import sortIcon from '../assets/images/sortIcon.png'
import deleteIcon from '../assets/images/delete-icon.svg'
import eye from '../assets/images/eye.svg'
import calculator from '../assets/images/Calculator.svg'
import adharcardFront from '../assets/images/AdharcardFront.png'
import adharcardBack from '../assets/images/AdharcardBack.png'
import adreessProof from '../assets/images/Address.png'
import cancelCheck from '../assets/images/cancelCheck.png'
import cartBagBig from '../assets/images/cart-bag-big.svg'
import smallproimg from '../assets/images/small-pro-img.png'
import NoImageFound from '../assets/images/no-data-founds.svg'
import noDataImage from '../assets/images/noDataImage.png'
import logOutIcon from '../assets/images/logout.svg'
import announce from '../assets/images/announce-img.svg'
import addOfferImg from '../assets/images/addOfferImg.png'
import NotFound from '../assets/images/not-found-vector.svg'
import OrderIcon from '../assets/images/order-icon.svg'
import SalesIcon from '../assets/images/sales-icon.svg'
import RevenueIcon from '../assets/images/revenue-icon.svg'
import VisitorsIcon from '../assets/images/visitors-icon.svg'
import CustomerIcon from '../assets/images/customer-icon.svg'
import CalenderIcon from '../assets/images/calendar.svg'
import TrendingUp from '../assets/images/trending-up.svg'
import Banner from '../assets/images/banner.png'
import CopyIcon from '../assets/images/copy-icons.svg'
import DashboardIcon from '../assets/images/dashboard_icon.png'
import InquiryIcon from '../assets/images/inquiry_icon.png'
import ListingIcon from '../assets/images/listing_icon.png'
import CmsIcon from '../assets/images/cmd_icon.png'
import SettingIcon from '../assets/images/setting.png'
import vector from '../assets/images/Vector.png'
import prodListImg from '../assets/images/product-list-img.png'
import addBtnIcon from '../assets/images/addBtnIcon.png';
import vectorRight from '../assets/images/vectorRight.png';
import notification from '../assets/images/notification.svg';
import shieldTick from '../assets/images/shieldTick.svg';
import editPenDark from '../assets/images/edit-pen-dark.svg';
import gameBoy from '../assets/images/gameboy.svg';
import addInfoIconOne from '../assets/images/addInfoIconOne.svg';
import trace from '../assets/images/trace.svg';
import productsNotFound from '../assets/images/not-found/product.svg';
import inqNotFound from '../assets/images/not-found/inquiry.svg';
import newdelete1 from '../assets/images/newdelete.svg';
import closebtn from '../assets/images/closebtn.svg';
import upload from '../assets/images/upload.svg';
import about01 from '../assets/images/about-01.png';
import right01 from '../assets/images/right.svg';
import bannerlogo from '../assets/images/bannerlogo.png';
import basiclogo from '../assets/images/basiclogo.png';
import ourbest01 from '../assets/images/ourbest01.png';
import searchnormal from '../assets/images/search-normal.png';
import newnotification from '../assets/images/new-notification.png';
import newmail from '../assets/images/new-mail.png';
import slidebar from '../assets/images/slidebar.png';
import customer from '../assets/images/element-2.png';
import square from '../assets/images/task-square.png';
import profiletick from '../assets/images/profile-tick.png';
import notefavorite from '../assets/images/note-favorite.png';
import monitor from '../assets/images/monitor.png';
import event01 from '../assets/images/event01.png';
import inquiry01 from '../assets/images/inquiry.png';
import avtar from '../assets/images/avtar.jpg';
import newsetting from '../assets/images/newsetting.png';
import Calendar1 from '../assets/images/Calendar.png';
import Edit2 from '../assets/images/edit-2.png';
import cal from '../assets/images/cal.png';
import location2 from '../assets/images/location2.png';
import phone from '../assets/images/phone.png';
import event from '../assets/images/event.png';
import user from '../assets/images/user.png';
import bank from '../assets/images/bank.png';
import company from '../assets/images/company.png';
import meeting from '../assets/images/meeting.png';
import wallet from '../assets/images/wallet.png';
import btn from '../assets/images/btn.png';
import desc from '../assets/images/desc.png';
import notificationNew from '../assets/images/notification-new.png';
import newuser from '../assets/images/new-User.png';
import passsecurity from '../assets/images/passsecurity.png';
import Task from '../assets/images/task.png';
import report from '../assets/images/document-text.png';
import exporton from '../assets/images/export.png';
import calone from '../assets/images/calone.png';


const assetImages = {
    inqNotFound,
    productsNotFound,
    trace,
    gameBoy,
    addInfoIconOne,
    editPenDark,
    shieldTick,
    vectorRight,
    notification,
    addBtnIcon,
    prodListImg,
    vector,
    CmsIcon,
    DashboardIcon,
    ListingIcon,
    InquiryIcon,
    SettingIcon,
    OrderIcon,
    SalesIcon,
    RevenueIcon,
    VisitorsIcon,
    CustomerIcon,
    CalenderIcon,
    TrendingUp,
    logo,
    menu,
    dashbicon,
    listing,
    bag,
    product,
    money,
    location,
    message,
    groupUser,
    profile,
    loginVector,
    blackLogo,
    downloadIcon,
    girlImg,
    producView,
    EditIcon,
    commingSoon,
    fileUpload,
    productImg,
    filterIcon,
    columnIcon,
    sortIcon,
    deleteIcon,
    eye,
    calculator,
    adharcardFront,
    adharcardBack,
    adreessProof,
    cancelCheck,
    cartBagBig,
    smallproimg,
    NoImageFound,
    noDataImage,
    logOutIcon,
    announce,
    addOfferImg,
    NotFound,
    Banner,
    CopyIcon,
    newdelete1,
    closebtn,
    upload,
    about01,
    right01,
    bannerlogo,
    basiclogo,
    ourbest01,
    searchnormal,
    newnotification,
    newmail,
    slidebar,
    customer,
    square,
    profiletick,
    notefavorite,
    monitor,
    event01,
    inquiry01,
    avtar,
    newsetting,
    Calendar1,
    Edit2,
    cal,
    phone,
    location2,
    user,
    event,
    company,
    bank,
    meeting,
    wallet,
    btn,
    desc,
    notificationNew,
    newuser,
    passsecurity,
    Task,
    report,
    exporton,
    calone,
    logout01,
    user01,
    linkurl,
    notped,
    detelenew,
    pluseadd,
    cridelete
}


export default assetImages