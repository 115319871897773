import axios from 'axios';
import { apiRequest } from './apiCall';
import { apipaths } from './apiPath';
const URL = 'http://localhost:8000/'


export const getBannerData = async () => {
    return apiRequest(apipaths.addBanner);
}


export const addBannerData = async (data) => {
    return await apiRequest(apipaths.addBannerPost, data);
}


export const editBannerData = async (data) => {
    return await apiRequest(apipaths.editBannerPost, data);
}


export const deleteBanner = async (id) => {
    return await apiRequest(apipaths.deleteBanner, null, null, id);
}


export const getAboutData = async () => {
    return apiRequest(apipaths.addAboutData);
}

export const addAboutData = async (data) => {
    return await apiRequest(apipaths.addWhoWeArePost, data);
}

export const editAboutDataApi = async (data) => {
    return await apiRequest(apipaths.editWhoWeArePost, data);
}


export const deleteWhoWeAre = async (id) => {
    return await apiRequest(apipaths.deleteWhoWeAre, null, null, id);
}


export const getCollectionData = async () => {
    return apiRequest(apipaths.addCollectionData);
}

export const getBrandData = async () => {
    return apiRequest(apipaths.getBrand);
}

export const getCategoryData = async () => {
    return apiRequest(apipaths.getCategory);
}

export const addOurCollectionData = async (data) => {
    return await apiRequest(apipaths.addCollectionPost, data);
}

export const editOurCollectionData = async (data) => {
    return await apiRequest(apipaths.editCollectionPost, data);
}


export const deleteCollection = async (id) => {
    return await apiRequest(apipaths.deleteCollection, null, null, id);
}


export const getOfferData = async () => {
    return apiRequest(apipaths.addOfferData);
}

export const addOurOfferData = async (data) => {
    return await apiRequest(apipaths.addOfferPost, data);
}

export const editOurOfferData = async (data) => {
    return await apiRequest(apipaths.editOfferPost, data);
}

export const deleteOffer = async (id) => {
    return await apiRequest(apipaths.deleteOffer, null, null, id);
}



export const getOurBestData = async () => {
    return apiRequest(apipaths.addOurBestData);
}

export const addNewOurBestData = async (data) => {
    return await apiRequest(apipaths.addOurBestPost, data);
}

export const editNewOurBestData = async (data) => {
    return await apiRequest(apipaths.editOurBestPost, data);
}

export const deleteOurBest = async (id) => {
    return await apiRequest(apipaths.deleteOurBest, null, null, id);
}

export const getVideoData = async () => {
    return apiRequest(apipaths.addVideoData);
}

export const addNewVideoData = async (data) => {
    return await apiRequest(apipaths.addVideoPost, data);
}

export const editNewVideoData = async (data) => {
    return await apiRequest(apipaths.editVideoPost, data);
}

export const deleteVideo = async (id) => {
    return await apiRequest(apipaths.deleteVideo, null, null, id);
}

export const getTestimonialData = async () => {
    return apiRequest(apipaths.addTestimonialData);
}

export const addNewTestimonialData = async (data) => {
    return await apiRequest(apipaths.addTestimonialPost, data);
}

export const editNewTestimonialData = async (data) => {
    return await apiRequest(apipaths.editTestimonialPost, data);
}

export const deleteTestimonial = async (id) => {
    return await apiRequest(apipaths.deleteTestimonial, null, null, id);
}

export const getBrandDataById = async (id) => {
    return await apiRequest(apipaths.getBrandById, null, { brandId: id });
}

export const addBrand = async (data) => {
    return await apiRequest(apipaths.addSareeBrand, data);
}

export const editBrand = async (data) => {
    return await apiRequest(apipaths.editBrand, data);
}

export const deleteBrand = async (id, brandId) => {
    return await apiRequest(apipaths.deletSareeFromBrand, null, { sareesId: id }, brandId, 'delete');
}

export const editSareeEditData = async (data, id) => {
    return await apiRequest(apipaths.editSareeBrand, data, null, id, 'edit');
}


export const addBasicData = async (data) => {
    return await apiRequest(apipaths.addBasicDescription, data);
}


export const editBasicData = async (data, id) => {
    return await apiRequest(apipaths.editBasicDescription, data, null, id, 'edit');
}


export const deleteBasic = async (params, brandId) => {
    return await apiRequest(apipaths.deleteBasicDescription, null, params, brandId, 'delete');
}



export const getAllProductData = async () => {
    return apiRequest(apipaths.addAllProductData);
}

export const getAllProductDataSpecific = async (params) => {
    return apiRequest(apipaths.addAllProductData, null, params);
}


export const addAllProductDataPost = async (data) => {
    return await apiRequest(apipaths.addALLProductDataPost, data);
}

export const editAllProductDataPost = async (data) => {
    return await apiRequest(apipaths.editALLProductDataPost, data);
}

export const getSubCategoryData = async (id) => {
    return await apiRequest(apipaths.subCategoryData, null, { parentCategoryId: id });
}


export const addAllBarnd = async (data) => {
    return await apiRequest(apipaths.brandNew, data);
}

export const deleteALLProduct = async (productId) => {
    return await apiRequest(apipaths.deleteALLProductData, null, null, productId, 'delete');
}


export const getServicesNewData = async () => {
    return apiRequest(apipaths.getServicesData);
}

export const addServicesPost = async (data) => {
    return await apiRequest(apipaths.addServicesPost, data);
}

export const editServicesPost = async (data) => {
    return await apiRequest(apipaths.editServicesPost, data);
}


export const getEventNewData = async () => {
    return apiRequest(apipaths.getEventsData);
}

export const addEventsPost = async (data) => {
    return await apiRequest(apipaths.addEventsPost, data);
}

export const editEventsPost = async (data) => {
    return await apiRequest(apipaths.editEventsPost, data);
}

export const getAnchoreNewData = async () => {
    return apiRequest(apipaths.getAnchoreData);
}

export const addAnchorePost = async (data) => {
    return await apiRequest(apipaths.addAnchorePost, data);
}

export const editAnchorePost = async (data) => {
    return await apiRequest(apipaths.editAnchorePost, data);
}


export const getOurNetworkNewData = async () => {
    return apiRequest(apipaths.getOurNetworkData);
}

export const addOurNetworkNewPost = async (data) => {
    return await apiRequest(apipaths.addOurNetworkPost, data);
}

export const editOurNetworNewPost = async (data) => {
    return await apiRequest(apipaths.editOurNetworkPost, data);
}


export const getVideoNewData = async () => {
    return apiRequest(apipaths.getVideoData);
}

export const addVideoNewPost = async (data) => {
    return await apiRequest(apipaths.addVideoPost, data);
}

export const editVideoNewPost = async (data) => {
    return await apiRequest(apipaths.editVideoPost, data);
}

export const getTestimonialsNewData = async () => {
    return apiRequest(apipaths.getTestimonialsData);
}

export const addTestimonialsNewPost = async (data) => {
    return await apiRequest(apipaths.addTestimonialsPost, data);
}

export const editTestimonialsNewPost = async (data) => {
    return await apiRequest(apipaths.editTestimonialsPost, data);
}

export const getGalleryNewData = async () => {
    return apiRequest(apipaths.getGalleryData);
}

export const addGalleryNewPost = async (data) => {
    return await apiRequest(apipaths.addGalleryPost, data);
}

export const editGalleryNewPost = async (data) => {
    return await apiRequest(apipaths.editGalleryPost, data);
}


export const getAboutPageNewData = async () => {
    return apiRequest(apipaths.getAboutPageData);
}

export const addAboutPageNewPost = async (data) => {
    return await apiRequest(apipaths.addAboutPagePost, data);
}

export const editAboutPageNewPost = async (data) => {
    return await apiRequest(apipaths.editAboutPagePost, data);
}

export const getMisVisNewData = async () => {
    return apiRequest(apipaths.getOurMisVisData);
}

export const getServicesDetailsNewData = async (query) => {
    return apiRequest(apipaths.getServicesDetailsData, null, query);
}
export const deleteSpecificAboutUs = async (id) => {
    return apiRequest(apipaths.deleteSpecificAboutUsPath, null, null, id);
}
export const deleteServiceSpecific = async (id) => {
    return apiRequest(apipaths.deleteServiceSpecificPath, null, null, id);
}
export const deleteSpecificEvent = async (id) => {
    return apiRequest(apipaths.deleteSpecificEventPath, null, null, id);
}
export const deleteSpecificNetwork = async (id) => {
    return apiRequest(apipaths.deleteSpecificNetworkPath, null, null, id);
}

export const deleteSpecificVideo = async (id) => {
    return apiRequest(apipaths.deleteSpecificVideoPath, null, null, id);
}

export const deleteSpecificTestimonial = async (id) => {
    return apiRequest(apipaths.deleteSpecificTestimonialPath, null, null, id);
}

export const deleteSpecifiGallery = async (id) => {
    return apiRequest(apipaths.deleteSpecifiGalleryPath, null, null, id);
}

export const deleteVisionMissionSpecific = async (id) => {
    return apiRequest(apipaths.deleteWhoWeAre, null, null, id);
}

export const addServicesPageData = async (data) => {
    return apiRequest(apipaths.addServicesPageDataPath, data);
}

export const deleteSpecificServiceDetails = async (id) => {
    return apiRequest(apipaths.deleteSpecificServiceDetailsPath, null, null, id);
}

export const getSpecificGallery = async (id) => {
    return apiRequest(apipaths.getSpecificGalleryPath, null, null, id);
}

export const getUpcomingEventData = async () => {
    return apiRequest(apipaths.getUpcomingEventNewData);
}
export const addUpcomingEventPost = async (data) => {
    return await apiRequest(apipaths.addUpcomingEventNewPost, data);
}

export const editUpcomingEventPost = async (data) => {
    return await apiRequest(apipaths.editUpcomingEventNewPost, data);
}
export const getSpeicicBanner = async (id) => {
    return apiRequest(apipaths.getSpeicicBannerPath, null, { bannerId: id });
}

export const getSpecificExporeService = async (id) => {
    return apiRequest(apipaths.getSpecificExporeServicePath, null, { servicesId: id });
}

export const getSpecificAtithiEvent = async (id) => {
    return apiRequest(apipaths.getSpecificAtithiEventPath, null, { id: id });
}

export const getSpecificAtithiForam = async (id) => {
    return apiRequest(apipaths.getSpecificAtithiForamPath, null, { id: id });
}

export const getSpecificNetwork = async (id) => {
    return apiRequest(apipaths.getSpecificNetworkPath, null, { id: id });
}


export const getContactUsData = async () => {
    return apiRequest(apipaths.getContactUsNewData);
}


export const addContactUSPost = async (data) => {
    return await apiRequest(apipaths.addContactUsNewPost, data);
}

export const editContactUSPost = async (data) => {
    return await apiRequest(apipaths.editContactUsNewPost, data);
}
export const getSpecificVideo = async (id) => {
    return apiRequest(apipaths.getSpecificVideoPath, null, { id: id });
}

export const getSpecificTestimonial = async (id) => {
    return apiRequest(apipaths.getSpecificTestimonialPath, null, { id: id });
}

export const getSpecificAboutUsDetails = async (id) => {
    return apiRequest(apipaths.getSpecificAboutUsDetailsPath, null, { id: id });
}

export const getSpecificVisionMission = async (id) => {
    return apiRequest(apipaths.getSpecificVisionMissionPath, null, { id: id });
}

export const getSpecificServiceDetails = async (id) => {
    return apiRequest(apipaths.getSpecificServiceDetailsPath, null, { id: id });
}

export const getSpecificUpcomingEvent = async (id) => {
    return apiRequest(apipaths.getSpecificUpcomingEventPath, null, { id: id });
}

export const deleteSpecificUpcommingEvents = async (id) => {
    return apiRequest(apipaths.deleteSpecificUpcommingEventsPath, null, null, id);
}

export const getSpecificContactUs = async (id) => {
    return apiRequest(apipaths.getSpecificContactUsPath, null, { id });
}



export const addHeaderPost = async (data) => {
    return await apiRequest(apipaths.addHeaderPost, data);
}

export const getHeaderData = async () => {
    return apiRequest(apipaths.getHeaderNewData);
}

export const deleteHeaderAll = async (id) => {
    return apiRequest(apipaths.deleteHeader, null, null, id);
}

export const getHeaderSubData = async (id) => {
    return apiRequest(apipaths.getHeadersubData, null, { parentId: id });
}


export const getOurbestNewData = async () => {
    return apiRequest(apipaths.getOurbestData);
}


export const addOurbestPost = async (data) => {
    return await apiRequest(apipaths.addOurbestNewPost, data);
}

export const getSpecificSkills = async (id) => {
    return apiRequest(apipaths.getOurbestData, null, { id });
}

export const addAdminContectNewPost = async (data) => {
    return await apiRequest(apipaths.addAdminContctPost, data);
}

export const getAdminContectNewPost = async () => {
    return await apiRequest(apipaths.getAdminContctPost);
}

export const getSpecificContactAdmin = async (id) => {
    return apiRequest(apipaths.getAdminContctPost, null, { id });
}


export const deleteAdminContct = async (id) => {
    return apiRequest(apipaths.deleteAdminContact, null, null, id);
}

export const deleteSkills = async (id) => {
    return apiRequest(apipaths.deleteSkills, null, null, id);
}

export const getContactInquiryData = async () => {
    return apiRequest(apipaths.getContactData);
}

export const getFreeQuataNewData = async () => {
    return apiRequest(apipaths.getFreeQuateData);
}

export const deleteFreeQuateNew = async (id) => {
    return apiRequest(apipaths.deleteFreeQuate, null, null, id);
}

export const deleteWorkContactNew = async (id) => {
    return apiRequest(apipaths.deleteWorkContact, null, null, id);
}

export const addVissionMissionFunc = async (data) => {
    return apiRequest(apipaths.addVissionMission, data);
}

export const getVissionMissionAllFunc = async () => {
    return apiRequest(apipaths.getVissionMission);
}

export const getVissionMissionSpecFunc = async (id) => {
    return apiRequest(apipaths.getVissionMission, null, { id });
}

export const deleteVissionMissionSpecFunc = async (id) => {
    return apiRequest(apipaths.deleteVissionMission, null, null, id);
}


export const editProfilePost = async (data) => {
    return await apiRequest(apipaths.editProfile, data);
}

export const getAdminAllData = async () => {
    return apiRequest(apipaths.getAdminNewData);
}

export const getTitles = async () => {
    return apiRequest(apipaths.getTitle);
}

export const updateTitles = async (data) => {
    return apiRequest(apipaths.updateTitle, data);
}

export const getTopFooter = async () => {
    return apiRequest(apipaths.getFooterTop);
}

export const addFooterNewTopData = async (data) => {
    return apiRequest(apipaths.addFooterTopData, data);
}

export const getFooterData = async (id) => {
    return apiRequest(apipaths.getFooterTop, null, { id });
}

export const getBottomFooter = async () => {
    return apiRequest(apipaths.getFooterMenu);
}

export const deleteBottomFooter = async (id) => {
    return apiRequest(apipaths.deleteFooterMenu, null, null, id);
}

export const addFooterBottomData = async (data) => {
    return apiRequest(apipaths.addFootermenu, data);
}


export const getFootermenuData = async (id) => {
    return apiRequest(apipaths.getFooterMenu, null, { id });
}

export const getServicesNewDataHome = async () => {
    return apiRequest(apipaths.getServicesNewDataHomePath);
}
export const getSpecificExporeServiceHome = async (id) => {
    return apiRequest(apipaths.getServicesNewDataHomePath, null, { homeservicesId: id });
}
export const editServicesPostHome = async (data) => {
    return apiRequest(apipaths.editServicesPostHomePath, data);
}
export const deleteServiceSpecificHome = async (id) => {
    return apiRequest(apipaths.deleteServiceSpecificHomePath, null, null, id);
}


// software

export const getAllCustomerData = async (params = null) => {
    return apiRequest(apipaths.getAllCustomerDataPath, null, params);
}

export const deleteCustomer = async (id) => {
    return apiRequest(apipaths.deleteCustomerPath, null, null, id);
}

export const addEditCustomer = async (data) => {
    return apiRequest(apipaths.addEditCustomerPath, data);
}

export const addSchedule = async (data) => {
    return apiRequest(apipaths.addSchedulePath, data);
}

export const getScheduleDataList = () => {
    return apiRequest(apipaths.getScheduleDataListPath)
}

export const addEditvenderService = (data) => {
    return apiRequest(apipaths.addEditvenderServicePath, data)
}

export const getAllSevices = () => {
    return apiRequest(apipaths.getAllSevicesPath)
}

export const deleteServiceVender = (id) => {
    return apiRequest(apipaths.deleteServiceVenderPath, null, null, id);
}

export const addEditVender = (data) => {
    return apiRequest(apipaths.addEditVenderPath, data)
}

export const getAllVenders = (params = null) => {
    return apiRequest(apipaths.getAllVendersPath, null, params)
}

export const deleteVender = (id) => {
    return apiRequest(apipaths.deleteVenderPath, null, null, id);
}

export const addEditEvent = (data) => {
    return apiRequest(apipaths.addEditEventPath, data)
}

export const getAllEvents = (params = null) => {
    return apiRequest(apipaths.getAllEventsPath, null, params)
}

export const getSpeficCustomerDetails = (params = null) => {
    return apiRequest(apipaths.getSpeficCustomerDetailsPath, null, params)
}

export const getAllVendersSpecific = (params = null) => {
    return apiRequest(apipaths.getAllVendersSpecificPath, null, params)
}

export const getSpecificEvent = (params = null) => {
    return apiRequest(apipaths.getSpecificEventPath, null, params)
}
export const deleteEventBooking = (id) => {
    return apiRequest(apipaths.deleteEventBookingPath, null, null, id);
}

export const getNotification = (id) => {
    return apiRequest(apipaths.notificationPath);
}

export const removeNotification = (data = {}) => {
    return apiRequest(apipaths.removeNotificationPath, data);
}

export const deleteScheduleApi = (id) => {
    return apiRequest(apipaths.deleteSchedulePath, null, null, id);
}

export const getSubAdmin = (params = {}) => {
    return apiRequest(apipaths.getSubAdminPath, null, params)
}

export const addSubAdmin = (data = {}) => {
    return apiRequest(apipaths.addSubAdminPath, data)
}

export const deleteSubAdmin = (id) => {
    return apiRequest(apipaths.deleteSubAdminPath, null, null, id)
}

export const getModules = () => {
    return apiRequest(apipaths.getModulesPath)
}

export const getTasks = (params = null) => {
    return apiRequest(apipaths.getTasksPath, null, params)
}

export const assignTasks = (data) => {
    return apiRequest(apipaths.assignTasksPath, data)
}

export const deleteTask = (id) => {
    return apiRequest(apipaths.deleteTaskPath, null, null, id)
}

export const changeTaskStatusApi = (id) => {
    return apiRequest(apipaths.changeTaskStatus, null, null, id)
}

export const getVendorReport = (params = null) => {
    return apiRequest(apipaths.getVendorReportPath, null, params)
}

export const getEventReport = (params = null) => {
    return apiRequest(apipaths.getEventReportPath, null, params)
}

export const getOtherExpence = (params = null) => {
    return apiRequest(apipaths.getOtherExpencePath, null, params)
}

export const getTaskCount = (params = null) => {
    return apiRequest(apipaths.getTaskCountPath, null, params)
}

export const taskOpend = (params = null) => {
    return apiRequest(apipaths.taskOpendPath, null, params)
}

export const addColseEvent = (id) => {
    return apiRequest(apipaths.addPostClosePath, null, null, id)
}

export const getMeetingNotification = () => {
    return apiRequest(apipaths.getMeetingNotificationPath);
}

export const removeNotificationMetting = (data = {}) => {
    return apiRequest(apipaths.removeNotificationMettingPath, data);
}

export const getNewNotificationsData = () => {
    return apiRequest(apipaths.getNewNotificationsDataPath);
}

export const setAllOpendNotification = () => {
    return apiRequest(apipaths.setAllOpendNotificationPath);
}

export const addEditFarm = (data = {}) => {
    return apiRequest(apipaths.addEditFarmPath, data);
}

export const getFarm = (params = {}) => {
    return apiRequest(apipaths.getFarmPath, null, params);
}
export const deleteFarm = (id) => {
    return apiRequest(apipaths.deleteFarmPath, null, null, id)
}

export const checkLogin = (data = {}) => {
    return apiRequest(apipaths.login, data)
}

export const getSpecificFarm = (params = {}) => {
    return apiRequest(apipaths.getSpecificFarmPath, null, params)
}

// new

export const getNewAllSlot = (body = null) => {
    return apiRequest(apipaths.getAllSlot, body);
}

export const addSloats = (data) => {
    return apiRequest(apipaths.addSloatsPath, data);
}

export const deleteSloat = (data) => {
    return apiRequest(apipaths.deleteSloatPath, data);
}

export const getAdmin = (data) => {
    return apiRequest(apipaths.getAdminPath, data);
}

export const getMettings = (data) => {
    return apiRequest(apipaths.getMettingsPath, data);
}

export const getAdminContactUs = (data) => {
    return apiRequest(apipaths.getAdminContactUsPath, data);
}

export const deletEvent = (id) => {
    return apiRequest(apipaths.deletEvent, null, null, id)
}

export const deleteSpecificContactUs = async (id) => {
    return apiRequest(apipaths.deleteSpecificContactUsPath, null, null, id);
}

export const getDates = () => {
    return apiRequest(apipaths.getDatePath);
}