import { Box } from '@mui/material'
import React from 'react'
import DashboardLayout from '../base/DashboardLayout'
import assetImages from '../constant/enum'

const NotFound = () => {
  return (
    <>
     <DashboardLayout text='404 Not Found Page'>
        {/* Page Start */}
        <div className="page-start">
          <div className="white-box-main">
            <Box className='not-found-page-main'>
                <Box className='custom-container'>
                    <Box className='img-area-main'>
                        <img src={assetImages.NotFound} alt='img' />
                    </Box>
                    <Box className='text'>Oops! Sorry, we could not find the page</Box>
                </Box>
            </Box>
          </div>
        </div>
     </DashboardLayout>

    </>
  )
}

export default NotFound