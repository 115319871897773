export const GET_SIDEBAR = 'GET_SIDEBAR'
export const GET_SIDEBAR_START = 'GET_SIDEBAR_START'
export const GET_SIDEBAR_ERROR = 'GET_SIDEBAR_ERROR'


export function getSideBarStart(){
    return{
        type:GET_SIDEBAR_START
    }
}

export function getSideBarData(data){
    return{
        type:GET_SIDEBAR,
        payload:data
    }
}

export function getSideBarError(){
    return{
        type:GET_SIDEBAR_ERROR
    }
}