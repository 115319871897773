import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import assetImages from "../constant/enum";
import { ScaleLoader } from 'react-spinners';
import Schema from '../services/ValidationServices';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";

const SignUp = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [IsRevealPassword, setIsRevealPassword] = useState(false);
    const [keepSignIn, setKeepSignIn] = useState(false);
    const dispatch = useDispatch()

    return (
        <>
            <div className="login-page-main-area">
                <div className="inner-box-login">
                    <div className="left-side-vector-area">
                        <img src={assetImages.loginVector} alt="img" />
                    </div>
                    <div className="right-side-area-main">
                        <div className="inner-box-login-right-side">
                            <div className="logo-row">
                                <img src={assetImages.logo} alt="img" />
                            </div>
                            <div className="wel-text text-blue">Welcome Back</div>
                            <div className="small-des">
                                Welcome back! Please enter your details
                            </div>
                            <Formik
                                initialValues={{ EmailId: "", password: "", }}
                                validationSchema={Schema.login}
                                onSubmit={(values) => {
                                    console.log({ values })
                                }}
                            >
                                {({ errors, values, touched, setFieldValue }) => (
                                    <Form>
                                        <div className="login-form-main">
                                            <div className="form-field">
                                                <div className="labal">Email</div>
                                                <input type="text" placeholder="abc@gmail.com" values={values.EmailId}
                                                    className={"form-control" + (errors.EmailId && touched.EmailId ? " is-invalid" : "")}
                                                    onChange={(e) => setFieldValue("EmailId", e.target.value)} />
                                                <ErrorMessage name="EmailId" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-field">
                                                <div className="labal">Phone Number</div>
                                                <input type="text" placeholder="Enter Mobile" values={values.EmailId}
                                                    className={"form-control" + (errors.EmailId && touched.EmailId ? " is-invalid" : "")}
                                                    onChange={(e) => setFieldValue("EmailId", e.target.value)} />
                                                <ErrorMessage name="EmailId" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-field">
                                                <div className="labal">Password</div>
                                                <input name="password" type={IsRevealPassword ? "text" : "password"} placeholder="Enter password"
                                                    values={values.password}
                                                    className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                                                    onChange={(e) => setFieldValue("password", e.target.value)}
                                                />
                                                <i className={IsRevealPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                                                    onClick={() => setIsRevealPassword((prevState) => !prevState)}
                                                ></i>
                                                <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-field">
                                                <div className="labal">Confirm Password</div>
                                                <input type="text" placeholder="Enter Password" values={values.EmailId}
                                                    className={"form-control" + (errors.EmailId && touched.EmailId ? " is-invalid" : "")}
                                                    onChange={(e) => setFieldValue("EmailId", e.target.value)} />
                                                <ErrorMessage name="EmailId" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="login-btn">
                                                <button className={`comman-btn ${keepSignIn ? '' : 'disabled-btn'}`} type="submit">
                                                    {loading ? <ScaleLoader color={"#fff"} height={15} width={2} /> : 'Create'}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            <div className="text-center text-center">
                                <p className="color-1c">Already have an account? <span className="color-85"><Link to="/">Login here</Link></span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignUp