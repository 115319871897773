import { Breadcrumbs } from '@mui/material';
import { Typography } from '@mui/material';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import DashboardLayout from '../base/DashboardLayout';
import BreadCum from '../components/common/BreadCum';
import UserProfile from '../components/UserProfile/UserProfile';
import assetImages from '../constant/enum';

const UserSettings = () => {
    const [activeTab, setActiveTab] = useState(1);
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="/" onClick={() => { }}>
            Home
        </Link>,
        <Typography key="3" color="text.primary">
            User Profile
        </Typography>,
    ];
    const [menuItems] = useState([
        { id: 1, icon: assetImages.editPenDark, title: "Edit Profile", active: true },
        { id: 2, icon: assetImages.notification, title: "Notification" },
        { id: 3, icon: assetImages.shieldTick, title: "Password & Security" },
    ])
    const gridOneArgs = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, }
    const gridTwoArgs = { xs: 12, sm: 12, md: 6, lg: 6, xl: 6, }
    return (
        <DashboardLayout text='Dashboard'>
            <div className='bread-background'>
                <BreadCum breadcrumbs={breadcrumbs} />
            </div>

            <div className="page-start">

                <div className='user-setting-wrapper '>

                    <div className="content-wrapper">
                        <div className="content-box nae-class-all">
                            {activeTab === 1 && <UserProfile gridOneArgs={gridOneArgs} gridTwoArgs={gridTwoArgs} />}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default UserSettings