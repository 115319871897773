import * as Yup from "yup";


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Schema = {
  //login form
  login: Yup.object().shape({
    EmailId: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    password: Yup.string().required("Password is required."),
  }),
  //forgot password
  forgotData: Yup.object().shape({
    EmailId: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
  }),
  // Reset Passwords
  resetPassword: Yup.object().shape({
    password: Yup.string()
      .required("Password is required.")
      .min(6, "Password must be 6 Character"),
    confirm_password: Yup.string()
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      })
      .required("Confirm Password Required"),
  }),
  //Reject Seller Request form
  rejectSeller: Yup.object().shape({
    Reason: Yup.string().required("Reason of Reject is required."),
  }),
  //invite subadmin
  invite: Yup.object().shape({
    EmailId: Yup.string()
      .email("Email is invalid")
      .required("Email is required"),
    // Password: Yup.string().required("Password is required."),
    Name: Yup.string().required("Name is required."),
    mobileNumber: Yup.string()
      .ensure()
      .required("Phone number is required.")
      .matches(phoneRegExp, "Phone number is not valid")
      .max(10, "Phone number max legth is 10.")
      .min(10, "Phone number min legth is 10."),
  }),
  //offer validation
  offer: Yup.object().shape({
    Name: Yup.string()
      .required("Enter Offer Name"),
    DisCodeMethod: Yup.string()
      .required("Select Discount Code Method"),
    // DisCode: Yup.string()
    // .required("Discount Code Is Required"),
    DisValue: Yup.string()
      .required("Discount Value Is required"),
    DisType: Yup.string()
      .required("Select Discount Type"),
    StartDate: Yup.string()
      .required("Select Start Date"),
    StartTime: Yup.string()
      .required("Select Start Time"),
    EndDate: Yup.string()
      .required("Select End Date"),
    EndTime: Yup.string()
      .required("Select End Time"),
  }),
  //announcement subadmin
  announcement: Yup.object().shape({
    Title: Yup.string().required("Title is Required"),
    Descriptions: Yup.string().required('Description is Required')
  }),
  // FAQ Cat
  faqForm: Yup.object().shape({
    name: Yup.string()
      .required("Category Name is required"),
  }),
  brandForm: Yup.object().shape({
    name: Yup.string()
      .required("Brand Name is required"),
  }),
  // Faq Question Answer
  FaqQue: Yup.object().shape({
    question: Yup.string()
      .required("Questions is required"),
    answer: Yup.string()
      .required("Answer is required"),
  }),

  // ManageCateogty
  MainCat: Yup.object().shape({
    CategoryName: Yup.string()
      .required("Category Name is required"),
  }),

  // subCat 
  SubCat: Yup.object().shape({
    SubCatName: Yup.string()
      .required("Sub-Category Name is required"),
  }),
  // Specific Cat 
  SpecificCat: Yup.object().shape({
    SpecificCatName: Yup.string()
      .required("Specific-Category Name is required"),
  }),
  // Add About us
  addAboutData: Yup.object().shape({
    title01: Yup.string()
      .required("Title is required"),
    title02: Yup.string()
      .required("Title is required"),
    count01: Yup.string()
      .required("Count is required"),
    count02: Yup.string()
      .required("Count is required"),
    description: Yup.string()
      .required("Description is required"),
  }),

  // Add Collection
  addCollectionData: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    url: Yup.string()
      .required("Link is required"),
    brandId: Yup.string()
      .required("brand is required"),
    categoryId: Yup.string()
      .required("category is required"),
  }),


  // Add Offer
  addOfferData: Yup.object().shape({
    url: Yup.string()
      .required("Link is required"),
  }),

  // Add Our Best
  addOurBestData: Yup.object().shape({
    title: Yup.string()
      .required("title is required"),
    description: Yup.string()
      .required("Description is required"),
  }),


  // Add Video
  addVideoData: Yup.object().shape({
    name: Yup.string()
      .required("name is required"),
    title: Yup.string()
      .required("title is required"),
    url: Yup.string()
      .required("url is required"),
  }),


  // Add Testimonial
  addtestimonialNewData: Yup.object().shape({
    name: Yup.string()
      .required("name is required"),
    title: Yup.string()
      .required("title is required"),
    url: Yup.string()
      .required("url is required"),
  }),

  // Add Image Type
  addImageTypeData: Yup.object().shape({
    type: Yup.string()
      .required("Type is required"),
    url: Yup.string()
      .required("url is required"),
  }),

  // Add Basic desicription
  addOurBestData: Yup.object().shape({
    title: Yup.string()
      .required("title is required"),
    type: Yup.string()
      .required("type is required"),

  }),


  // Add Product
  addProduct: Yup.object().shape({
    title: Yup.string()
      .required("title is required"),
    description: Yup.string()
      .required("Description is required"),
    size: Yup.array().min(1, "Select at least ").required(" Size required"),
    color: Yup.array().min(1, "at least 1").required(" Color required"),
    type: Yup.string()
      .required("type is required"),
    minPrice: Yup.string()
      .required("MinPrice is required"),
    maxPrice: Yup.string()
      .required("MaxPrice is required"),

  }),


  // Add Career
  addCareerData: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    description: Yup.string()
      .required("Description is required"),
  }),
  // Add Career
  addBlogData: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    description: Yup.string()
      .required("Description is required"),
    // formImages: Yup.string()
    //   .required("image is required"),
  }),
  // Add Terms
  addTerms: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    description: Yup.string()
      .required("Description is required"),
    // formImages: Yup.string()
    //   .required("image is required"),
  }),
  // Add Banner
  addBanner: Yup.object().shape({
    // url: Yup.string()
    //   .required("Link is required"),
    // description: Yup.string()
    //   .required("Description is required"),
    // title: Yup.string()
    //   .required("Title is required"),
  }),
  addSkills: Yup.object().shape({
    description: Yup.string()
      .required("Description is required"),
    title: Yup.string()
      .required("Title is required"),
  }),
  // Add services
  addservices: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    url: Yup.string()
      .required("Link is required"),
  }),

  // Add services
  addservicesHome: Yup.object().shape({
    url: Yup.string()
      .required("Link is required"),
  }),


  // Add Event
  addEvent: Yup.object().shape({
    description: Yup.string()
      .required("Description is required"),
    title01: Yup.string()
      .required("title01 is required"),
    title02: Yup.string()
      .required("title02 is required"),
    count01: Yup.string()
      .required("count01 is required"),
    count02: Yup.string()
      .required("count02 is required"),
  }),

  // Add Event
  addContactAdmin: Yup.object().shape({
    name1: Yup.string()
      .required("name1 is required"),
    mobile1: Yup.string()
      .required("mobile1 is required"),
    name2: Yup.string()
      .required("name2 is required"),
    mobile2: Yup.string()
      .required("mobile2 is required"),
    address: Yup.string()
      .required("address is required"),
  }),

  // Add Network
  addNetwork: Yup.object().shape({
    url: Yup.string()
      .required("Link is required"),
  }),

  // Add Video
  addVideo: Yup.object().shape({
    // title: Yup.string()
    //   .required("Title is required"),
    // url: Yup.string()
    // .required("Link is required"),
  }),

  // Add testimonilas
  addtestimonilas: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    review: Yup.string()
      .required("Review is required"),
  }),

  // Add Gallery
  addGallery: Yup.object().shape({
    url: Yup.string()
      .required("Link is required"),
  }),

  // Add about us
  addAboutUSS: Yup.object().shape({
    description: Yup.string()
      .required("Description is required"),
    description1: Yup.string()
      .required("Description1 is required"),
  }),


  // Add MisVis
  addMisVis: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    description: Yup.string()
      .required("Description is required"),
  }),

  // Add services-details
  addServicesDetailsAll: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    servicename: Yup.string()
      .required("Service Name is required"),
  }),

  // Add Upcoming Event
  addUpcomingEvent: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    servicename: Yup.string()
      .required("Service Name is required"),
    date: Yup.string()
      .required("Date  is required"),
  }),

  // Add Header
  addHeader: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    url: Yup.string()
      .required("Link is required"),
  }),


  // Add contact us Address
  addContactAddress: Yup.object().shape({
    location: Yup.string()
      .required("location is required"),
    link: Yup.string()
      .required("link is required"),
    address: Yup.string()
      .required("address is required"),
  }),

  // Add user data
  newUserData: Yup.object().shape({
    fullName: Yup.string()
      .required("Full Name is required"),
    email: Yup.string()
      .required("Email is required"),
    mobile: Yup.string()
      .required("Mobile is required"),
    // password: Yup.string()
    //   .required("Password is required").matches(
    //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
    //     "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    //   ),
    // passwordConfirmation: Yup.string().required('Please retype your password.')
    //   .oneOf([Yup.ref('password'), null], 'Passwords must match')
  }),

  // Add user data
  footerMenuAdd: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    link: Yup.string()
      .required("Enter link is required"),
  }),


  // Add top footer
  newFooterTopData: Yup.object().shape({
    name1: Yup.string()
      .required("name1 is required"),
    name2: Yup.string()
      .required("name2 is required"),
    mobile1: Yup.string()
      .required("mobile1 is required"),
    mobile2: Yup.string()
      .required("mobile2 is required"),
    city1: Yup.string()
      .required("city1 is required"),
    city2: Yup.string()
      .required("city2 is required"),
    address1: Yup.string()
      .required("address1 is required"),
    address2: Yup.string()
      .required("address2 is required"),
    facebook: Yup.string()
      .required("facebook link required"),
    instagram: Yup.string()
      .required("instagram link required"),
    twitter: Yup.string()
      .required("twitter link required"),
    youtube: Yup.string()
      .required("youtube link required"),
  }),

  // Add Three Banner
  addthreebanner: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    link: Yup.string()
      .required("Link is required"),
  }),

  // Add Two Banner
  addtwobanner: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    link: Yup.string()
      .required("Link is required"),
  }),

  // Add Top Brand
  addtopbrand: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    link: Yup.string()
      .required("Link is required"),
  }),

  // add latest offers

  addlatestoffers: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    link: Yup.string()
      .required("Link is required"),
  }),

  //software
  addCustomer: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
    mobile: Yup.string()
      .required("mobile number is required"),
    // referance_by: Yup.string()
    //   .required("Referance By is required"),
    address: Yup.string()
      .required("Address is required"),
    // farmName: Yup.string()
    //   .required("Farm Name is required"),
    // officeAddress: Yup.string()
    //   .required("Office Address is required"),

  }),

  addVender: Yup.object().shape({
    service: Yup.string()
      .required("Service is required"),
    vendor_name: Yup.string()
      .required("Vendor Name number is required"),
    comapny_name: Yup.string()
      .required("Comapny Name By is required"),
    mobile: Yup.string()
      .required("Mobile Number is required"),
    address: Yup.string()
      .required("Address is required"),
    accountHolderName: Yup.string()
      .required("Address is required"),
    accountNo: Yup.string()
      .required("Address is required"),
    ifsc: Yup.string()
      .required("Address is required"),
    bankName: Yup.string()
      .required("Address is required"),
  }),

  bookEvent: Yup.object().shape({
    client_name: Yup.string()
      .required("Client Name is required"),
    event_name: Yup.string()
      .required("Event Name is required"),
    start_date: Yup.string()
      .required("Start Date is required"),
    end_date: Yup.string()
      .required("End Date is required"),
    total_budget: Yup.string()
      .required("Total Budget is required"),
    advance_payment: Yup.string()
      .required("Advance Payment is required"),
    remaining_payment: Yup.string()
      .required("Remaining Payment is required"),
  }),
  adminService: Yup.object().shape({
    name: Yup.string()
      .required("Name is required"),
  }),

  scheduleMetthing: Yup.object().shape({
    date: Yup.string()
      .required("Date is required"),
    time: Yup.string()
      .required("Time is required"),
    address: Yup.string()
      .required("Address is required"),
    event: Yup.string()
      .required("Event is required"),
    customer: Yup.string()
      .required("Select Customer is required"),
  }),


  taskasign: Yup.object().shape({
    title: Yup.string()
      .required("Title is required"),
    description: Yup.string()
      .required("Description is required"),
    startDate: Yup.string()
      .required("Start Date is required"),
    endDate: Yup.string()
      .required("End date is required"),
    subAdminId: Yup.string()
      .required("Sub Admin is required"),
  }),

  // Add user data
  staffData: Yup.object().shape({
    userName: Yup.string()
      .required("User Name is required"),
    email: Yup.string()
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required").matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
  }),
  editstaffData: Yup.object().shape({
    userName: Yup.string()
      .required("User Name is required"),
    email: Yup.string()
      .required("Email is required"),
  }),
  addFarm: Yup.object().shape({
    farmName: Yup.string()
      .required("Farm Name is required"),
    ownerName: Yup.string()
      .required("Owner Name is required"),
    address: Yup.string()
      .required("Address is required"),
    mobile: Yup.string()
      .required("Mobile Number is required"),

  }),
};

export default Schema;
