import { CircularProgress } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DashboardLayout from "../../base/DashboardLayout";
import { addContactUSPost, editContactUSPost, getSpecificContactUs } from "../../environment/Api";
import { apiRequest } from "../../environment/Api/apiCall";
import { apipaths } from "../../environment/Api/apiPath";
import Schema from "../../services/ValidationServices";
import assetImages from '../../constant/enum';
import { NavLink, useNavigate, useParams } from "react-router-dom";

const ContactUsEdit = () => {
    const [selectedImages, setSelectedImages] = useState("");
    const [files, setFiles] = useState([]);
    const [isUploding, setUploding] = useState(false);
    const [error, setError] = useState({});
    const [editEvent, setEditEvent] = useState({});
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let id = params?.id;
        getSpecificContactUs(id).then((res) => {
            setEditEvent(res.data && res.data.ContactusData && res.data.ContactusData[0])
            setFiles([{ original: res.data && res.data.ContactusData && res.data.ContactusData[0].image }]);
        })
    }, [])

    const uploadFileHandler = async (event) => {
        let selectedImages = (event.target.files);
        const formData = new FormData();
        setUploding(true);
        for (let i = 0; i < selectedImages.length; i++) {
            formData.append("file", selectedImages[i]);
            setUploding(true);
        }
        const response = await apiRequest(apipaths.fileUpload, formData);
        if (response?.statusCode === 200) {
            let totalImages = [];
            if (Array.isArray(response.data) === false) {
                totalImages.push(response.data);
            } else {
                totalImages = totalImages.concat(response.data);
            }
            setFiles(totalImages);
            setUploding(false);
            setSelectedImages("");
            toast.success("File Upload successFully");
        } else {
            setUploding(false);
            console.warn("something wrong while uploading");
        }
    };

    const ContactUs = async (values) => {
        if (files.length == 0) {
            setError({ image: "Please Select Image." })
            return
        }
        if (Object.keys(editEvent).length > 0) {
            await addContactUSPost({
                image: files[0].original,
                address: values.address,
                location: values.location,
                link: values.link,
                ContactusId: editEvent._id
            }).then((res) => {
                toast.success('Edit Successfully.')
                navigate('/cms/contact-us')
            }).catch((err) => {
                console.log(err);
            })
        }
    }
    return (
        <>
            <DashboardLayout text='Edit Address'>
                <div className="faq-form">
                    {
                        Object.keys(editEvent).length > 0 &&
                        <Formik
                            initialValues={{
                                location: Object.keys(editEvent).length > 0 ? editEvent.location : "",
                                address: Object.keys(editEvent).length > 0 ? editEvent.address : "",
                                link: Object.keys(editEvent).length > 0 ? editEvent.link : "",
                            }}
                            validationSchema={Schema.addContactAddress}
                            onSubmit={(values) => {
                                ContactUs(values);
                            }}
                        >
                            {({ errors, values, touched, setFieldValue }) => (
                                <Form>
                                    <div className="faq-input-form">
                                        <div className="new-faq">
                                            <label>Location</label>
                                            <input type="text" placeholder="Enter Location" id="location" name="location"
                                                value={values.location}
                                                className={
                                                    "form-control" +
                                                    (errors.location && touched.location
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onChange={(e) =>
                                                    setFieldValue("location", e.target.value)
                                                }
                                            />
                                            <ErrorMessage
                                                name="location"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="new-faq">
                                            <label>Location</label>
                                            <input type="text" placeholder="Enter link" id="link" name="link"
                                                value={values.link}
                                                className={
                                                    "form-control" +
                                                    (errors.link && touched.link
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onChange={(e) =>
                                                    setFieldValue("link", e.target.value)
                                                }
                                            />
                                            <ErrorMessage
                                                name="link"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="new-faq">
                                            <label>Address </label>
                                            <textarea type="text" placeholder="Enter Address" id="address" name="address"
                                                value={values.address}
                                                className={
                                                    "form-control" +
                                                    (errors.address && touched.address
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                onChange={(e) =>
                                                    setFieldValue("address", e.target.value)
                                                }
                                            />
                                            <ErrorMessage
                                                name="address"
                                                component="div"
                                                className="invalid-feedback"
                                            />
                                        </div>
                                        <div className="in-faq">
                                            <div className="upload-img">
                                                <h3>Upload Image</h3>
                                                <div className="upload-img">
                                                    <div className="avatar-upload">
                                                        <div className="avatar-edit">
                                                            <input type='file' id="imageUpload" accept="image/x-png,image/gif,image/jpeg"
                                                                onChange={uploadFileHandler} />
                                                            <label for="imageUpload"></label>
                                                        </div>
                                                        <div className="avatar-preview">
                                                            <div id="imagePreview">
                                                                <img src={files && files[0].original} alt="" className="profile-img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="faq-submit">
                                            <NavLink to="/cms/contact-us">
                                                <button style={{ marginLeft: '1rem', marginRight: '1rem' }}>Back</button>
                                            </NavLink>
                                            <button>{Object.keys(editEvent).length > 0 ? 'Update' : 'Add'}</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    }
                </div>
            </DashboardLayout>
        </>
    )
}

export default ContactUsEdit;