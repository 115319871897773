import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import DashboardLayout from "../../base/DashboardLayout";
import BreadCum from "../common/BreadCum";
import assetImages from '../../constant/enum';
import { addSloats, getDates } from "../../environment/Api";
import { DatePicker } from 'antd';
import moment from "moment";
import { toast } from "react-toastify";
const { RangePicker } = DatePicker;
const breadcrumbs = [
    <NavLink to="/bookedslot">Dashboard</NavLink>,
    <Typography key="3" color="text.primary">Create a Meeting</Typography>,
];


const AddSlot = () => {
    // const [addSlot, setAddSlot] = useState({});
    // const [deleteSlot, setDeleteSlot] = useState({});
    // const [date, setDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    // const [sloatData, setSloatData] = useState([""]);
    const [error, setError] = useState([]);
    const [dateError, setDateError] = useState("");
    const [duplicateError, setDuplicateError] = useState("");
    const [datesArr, setDatesArr] = useState([]);
    const [dates, setDates] = useState([]);
    const [dateRangeError, setDateRangeError] = useState("");
    const navigate = useNavigate();

    const timeArr = [
        { label: "12:15 AM", value: "00:15" },
        { label: "12:30 AM", value: "00:30" },
        { label: "12:45 AM", value: "00:45" },
        { label: "01:00 AM", value: "01:00" },
        { label: "01:30 AM", value: "01:30" },
        { label: "02:00 AM", value: "02:00" },
        { label: "02:30 AM", value: "02:30" },
        { label: "03:00 AM", value: "03:00" },
        { label: "03:30 AM", value: "03:30" },
        { label: "04:00 AM", value: "04:00" },
        { label: "04:30 AM", value: "04:30" },
        { label: "05:00 AM", value: "05:00" },
        { label: "05:30 AM", value: "05:30" },
        { label: "06:00 AM", value: "06:00" },
        { label: "06:30 AM", value: "06:30" },
        { label: "07:00 AM", value: "07:00" },
        { label: "07:30 AM", value: "07:30" },
        { label: "08:00 AM", value: "08:00" },
        { label: "08:30 AM", value: "08:30" },
        { label: "09:00 AM", value: "09:00" },
        { label: "09:30 AM", value: "09:30" },
        { label: "10:00 AM", value: "10:00" },
        { label: "10:30 AM", value: "10:30" },
        { label: "11:00 AM", value: "11:00" },
        { label: "11:30 AM", value: "11:30" },
        { label: "12:00 AM", value: "12:00" },
        { label: "12:30 AM", value: "12:30" },
        { label: "01:00 PM", value: "13:00" },
        { label: "01:30 PM", value: "13:30" },
        { label: "02:00 PM", value: "14:00" },
        { label: "02:30 PM", value: "14:30" },
        { label: "03:00 PM", value: "15:00" },
        { label: "03:30 PM", value: "15:30" },
        { label: "04:00 PM", value: "16:00" },
        { label: "04:30 PM", value: "16:30" },
        { label: "05:00 PM", value: "17:00" },
        { label: "05:30 PM", value: "17:30" },
        { label: "06:00 PM", value: "18:00" },
        { label: "06:30 PM", value: "18:30" },
        { label: "07:00 PM", value: "19:00" },
        { label: "07:30 PM", value: "19:30" },
        { label: "08:00 PM", value: "20:00" },
        { label: "08:30 PM", value: "20:30" },
        { label: "09:00 PM", value: "21:00" },
        { label: "09:30 PM", value: "21:30" },
        { label: "10:00 PM", value: "22:00" },
        { label: "10:30 PM", value: "22:30" },
        { label: "11:00 PM", value: "23:00" },
        { label: "11:30 PM", value: "23:30" },
        { label: "12:00 PM", value: "24:00" },
    ]
    const checkError = () => {
        let validate = true;
        setDuplicateError();
        if (!(startDate && endDate)) {
            validate = false;
            setDateError("Please Enter Date.");
        } else {
            setDateError("");
        }
        setError([]);
        let err = [];
        datesArr.map((item) => {
            let obj = {};
            if (item.startTime === '') {
                validate = false;
                obj.startTime = 'Please Enter Start Time.';
            }
            if (item.endTime === '') {
                validate = false;
                obj.endTime = 'Please Enter end Time.';
            }
            err.push(obj);
        })
        // sloatData.map((item, index) => {
        //     if (item.length === 0) {
        //         err.push('Please Enter Value.');
        //         validate = false;
        //     } else {
        //         err.push('');
        //     }
        // })
        // let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
        // let tempDup = findDuplicates(sloatData);
        // console.log("sdkfhui", tempDup)
        // if (tempDup.length > 0) {
        //     validate = false;
        //     setDuplicateError('Please Enter Unique Time Slots.');
        // }
        setError(err);
        return validate
    }
    const handleSubmit = () => {
        let validate = checkError();
        if (validate) {
            let body = {
                data: datesArr
            }
            addSloats(body).then((res) => {
                toast.success('Added Successfully.')
                navigate('/createameeting')
            }).catch((err) => {
                console.log("jsguds", err)
            })
        }
    }
    useEffect(() => {
        getDatesData();
    }, [])
    const getDatesData = () => {
        getDates().then((res) => {
            console.log("DWcxygbhjn", res)
            let temp = res;
            let arr = [];
            temp.map((item) => {
                arr.push(item.date);
            })
            setDates(arr);
        })
    }
    console.log("sklhfduh", dates)
    useEffect(() => {
        Date.prototype.addDays = function (days) {
            var dat = new Date(this.valueOf())
            dat.setDate(dat.getDate() + days);
            return dat;
        }

        function getDates(startDate, stopDate) {
            setDateRangeError("");
            var dateArray = [];
            var currentDate = startDate;
            while (currentDate <= stopDate) {
                let obj = {
                    date: moment(currentDate).format('YYYY-MM-DD'),
                    startTime: '09:00',
                    endTime: '18:00',
                }
                dateArray.push(obj);
                currentDate = currentDate.addDays(1);
            }
            return dateArray;
        }

        var dateArray = getDates(new Date(startDate), (new Date(endDate)));
        let validate = true;
        dateArray.map((item) => {
            if (dates.includes(item.date)) {
                validate = false;
            }
        })
        if (validate === false) {
            setDateRangeError("Some Dates Are Match With Existing Records.");
            setDatesArr([]);
        } else {
            setDatesArr(dateArray);
        }
    }, [startDate, endDate]);

    const handleTimeChange = (value, index, key) => {
        let temp = [...datesArr];
        let obj = temp[index];
        obj[key] = value;
        temp[index] = obj;
        // console.log("dascxzgyhnj", temp)
        setDatesArr(temp);
    }

    return (
        <>
            <DashboardLayout text="Create a Meeting">
                <div className='new-d-flex'>
                    <BreadCum
                        breadcrumbs={breadcrumbs} />
                    <div className='d-flex'>
                        {/* <button className='comman-btn' >Visit Site</button> */}
                        <NavLink to="/createameeting">
                            <button className='comman-btn' style={{ marginLeft: '1rem', marginRight: '1rem' }}><img src={assetImages.addBtnIcon} alt="" />List Of Sloats</button>
                        </NavLink>
                    </div>
                </div>
                <div className="page-start">
                    <div className="nae-class-all new-slot-centr">
                        <div className="new-add-slot">
                            <div className="slot-box">
                                <div className="slot-hedding">
                                    <h3>Create a Slot</h3>
                                </div>
                                <div className="date-section">
                                    {/* {console.log("DSczxygb", startDate, endDate)} */}
                                    <div className="input-date">
                                        <RangePicker onChange={(e) => { setStartDate(moment(e[0]._d).format("YYYY-MM-DD")); setEndDate(moment(e[1]._d).format("YYYY-MM-DD")); }} />
                                        {/* <input type="date" onChange={(e) => { setDate(e.target.value); console.log("dscxzhun", e.target.value) }} /> */}
                                        {
                                            dateError && <div className="error">{dateError}</div>
                                        }
                                        {
                                            dateRangeError && <div className="error">{dateRangeError}</div>
                                        }
                                    </div>
                                    <div className="">
                                        {
                                            datesArr && datesArr.map((item, index) => (
                                                <>
                                                    <div className="d-flex add-data-class ">
                                                        <div className="">{item.date}</div>
                                                        <div className=" left-right-new-class">
                                                            {/* <div>Start Time</div> */}
                                                            <select className="select-new-class" onChange={(e) => { handleTimeChange(e.target.value, index, 'startTime') }} value={item.startTime}>
                                                                {
                                                                    timeArr && timeArr.map((timeItem, timeIndex) => (
                                                                        <option key={timeIndex} value={timeItem.value}>{timeItem.label}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {/* <input type='time' onChange={(e) => { handleTimeChange(e.target.value, index, 'startTime') }} value={item.startTime} /> */}
                                                            {
                                                                error.length > index && error[index].startTime && <div className="error">{error[index].startTime}</div>
                                                            }
                                                        </div>
                                                        <div className=" ">
                                                            {/* <label>End Time</label> */}
                                                            <select className="select-new-class" onChange={(e) => { handleTimeChange(e.target.value, index, 'endTime') }} value={item.endTime}>
                                                                {
                                                                    timeArr && timeArr.map((timeItem, timeIndex) => (
                                                                        <option key={timeIndex} value={timeItem.value}>{timeItem.label}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            {/* <input type='time' onChange={(e) => { handleTimeChange(e.target.value, index, 'endTime') }} value={item.endTime} /> */}
                                                            {
                                                                error.length > index && error[index].endTime && <div className="error">{error[index].endTime}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                    {/* {
                                        sloatData && sloatData.map((item, index) => (
                                            <div className="time-input">
                                                <div className="tim-input">
                                                    <input type="time" value={item} onChange={(e) => { handleChange(e.target.value, index) }} />
                                                    {
                                                        error.length > index && error[index] && <div className="error">{error[index]}</div>
                                                    }
                                                </div>
                                                {
                                                    index + 1 === sloatData.length &&
                                                    <div className="detele-btn">
                                                        <img src={assetImages.pluseadd} alt="Add " onClick={() => { handleAdd(); }} />
                                                    </div>
                                                }
                                                {
                                                    index !== 0 &&
                                                    <div className="detele-btn">
                                                        <img src={assetImages.detelenew} alt="delete" onClick={() => { handleDelete(index); }} />
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    } */}
                                </div>
                                {duplicateError && <div className="error">{duplicateError}</div>}
                                <div className="create-slot-btn">
                                    <button className='comman-btn' type="button" onClick={() => { handleSubmit() }} ><img src={assetImages.addBtnIcon} alt="" />Create </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </DashboardLayout>
        </>
    )
}

export default AddSlot;