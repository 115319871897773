import { Box, Link, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import DashboardLayout from "../../base/DashboardLayout";
import BreadCum from "../common/BreadCum";
import assetImages from '../../constant/enum';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { deleteSloat, getNewAllSlot } from "../../environment/Api";
import { toast } from "react-toastify";

const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/bookedslot" onClick={() => { }}>Dashboard</Link>,
    <Typography key="3" color="text.primary">Create a Meeting</Typography>,
];

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));


const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const CreateAMeeting = () => {
    const [expanded, setExpanded] = React.useState('panel0');
    const [createMettingData, setCreateMettingData] = useState(null);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteOpen, setDeleteOpen] = React.useState(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => { setDeleteOpen(false); setDeleteItem({}) }

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };


    useEffect(() => {
        getData()
    }, [])
    const getData = () => {
        getNewAllSlot().then((res) => {
            setCreateMettingData(res);
        })
    }
    // console.log("createMettingData", createMettingData)

    const handleDelete = () => {
        deleteSloat({ date: deleteItem.date }).then((res) => {
            getData();
            handleDeleteClose();
            toast.success("Deleted Successfully.")
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <>
            <DashboardLayout text="Create a Meeting">
                <div className='new-d-flex'>
                    <BreadCum
                        breadcrumbs={breadcrumbs} />
                    <div className='d-flex'>
                        {/* <button className='comman-btn' >Visit Site</button> */}
                        <NavLink to="/add-slot">
                            <button className='comman-btn' style={{ marginLeft: '1rem', marginRight: '1rem' }}><img src={assetImages.addBtnIcon} alt="" />Create a meeting</button>
                        </NavLink>
                    </div>
                </div>
                <div className="page-start">
                    <div className="nae-class-all">
                        {createMettingData && createMettingData.length > 0 && createMettingData?.map((item, index) => (
                            <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" className="inner-accrodian">
                                    <Typography className="new-acro-hed">{item.date}
                                        <div className="buton-add nre-add">
                                            <button onClick={() => { handleDeleteOpen(); setDeleteItem(item) }}><img src={assetImages.cridelete} alt="dsdff" /></button>
                                        </div>
                                        <div className="buton-add">
                                            <NavLink to={`/edit-slot/${item._id}`}>
                                                <button><img src={assetImages.notped} alt="notped" /> Edit</button>
                                            </NavLink>
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="new-acrodian-inner">
                                            <div className="time-slot">
                                                <ul>
                                                    {
                                                        item.sloatTime && item.sloatTime.map((timeItem, ind) => (
                                                            <li key={ind}>{timeItem.time}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        ))}

                    </div>
                </div>

            </DashboardLayout>


            <div className="delete-popup">
                <Modal
                    open={deleteOpen}
                    onClose={handleDeleteClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="modal-potion">
                        <div className="modal-hedding">
                            <h3>&nbsp;&nbsp;Are you sure you want to Delete?</h3>
                            <div className="close-faq" onClick={handleDeleteClose}>
                                <button ><img src={assetImages.closebtn} alt="close" /></button>
                            </div>
                        </div>
                        <div className="modal-delete">
                            <div className="can-de">
                                <button onClick={handleDeleteClose} className="cancel">Cancel</button>
                                <button onClick={handleDelete} className="delete">Delete</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default CreateAMeeting;