import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import configurestore from './Redux/Store';

const { store, persistor } = configurestore();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <PersistGate loading={null}  persistor={persistor} >
        <App />
    </PersistGate>
  </Provider>
);


