import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import assetImages from "../constant/enum";
import { ScaleLoader } from 'react-spinners';
import Schema from '../services/ValidationServices';
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { apiRequest } from "../environment/Api/apiCall";
import { apipaths } from '../environment/Api/apiPath'
import { toast } from "react-toastify";
const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [IsRevealPassword, setIsRevealPassword] = useState(false);
  const [keepSignIn, setKeepSignIn] = useState(false);
  const dispatch = useDispatch()

  const LoginBtn = async (values) => {
    setLoading(true)
    const data = {
      email: values?.EmailId,
      password: values?.password
    }
    const response = await apiRequest(apipaths.login, data);
    console.log("wdsxzcug", response)
    if (response?.status === 200) {
      toast.success(response?.message);
      localStorage.setItem("auth-token", response?.token);
      localStorage.setItem('LoginUser', JSON.stringify(response?.token))
      setLoading(false);
      navigate('/bookedslot');
    }
    else {
      setLoading(false);
      console.warn('something went wrong. Go Back To Home Page!!!..');
    }
  }


  return (
    <>
      <div className="login-page-main-area">
        <div className="inner-box-login">
          <div className="left-side-vector-area">
            <img src={assetImages.loginVector} alt="img" />
          </div>
          <div className="right-side-area-main">
            <div className="inner-box-login-right-side">
              <div className="logo-row">
                <img src={assetImages.logo} alt="img" />
              </div>
              <div className="wel-text text-blue">Welcome Back</div>
              <div className="small-des">
                Welcome back! Please enter your details
              </div>
              <Formik
                initialValues={{ EmailId: "", password: "", }}
                validationSchema={Schema.login}
                onSubmit={(values) => {
                  LoginBtn(values)
                }}
              >
                {({ errors, values, touched, setFieldValue }) => (
                  <Form>
                    <div className="login-form-main">
                      <div className="form-field">
                        <div className="labal">Email</div>
                        <input type="text" placeholder="Enter Email" values={values.EmailId}
                          className={"form-control" + (errors.EmailId && touched.EmailId ? " is-invalid" : "")}
                          onChange={(e) => setFieldValue("EmailId", e.target.value)} />
                        <ErrorMessage name="EmailId" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-field">
                        <div className="labal">Password</div>
                        <input name="password" type={IsRevealPassword ? "text" : "password"} placeholder="Enter password"
                          values={values.password}
                          className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                          onChange={(e) => setFieldValue("password", e.target.value)}
                        />
                        <i className={IsRevealPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                          onClick={() => setIsRevealPassword((prevState) => !prevState)}
                        ></i>
                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                      </div>
                      {/* <div className="forgot-row">
                        <div className="left-side">
                          <FormControlLabel control={<Checkbox defaultChecked />} label="Remember me" />
                        </div>
                        <Link to='/forgot-password'>
                          <span className="right-side"> Forgot password? </span>
                        </Link>
                      </div> */}
                      <div className="login-btn">
                        <button className={`comman-btn ${keepSignIn ? '' : 'disabled-btn'}`} type="submit">
                          {loading ? <ScaleLoader color={"#fff"} height={15} width={2} /> : 'Log In'}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <div className="text-center text-center">
                <p className="color-1c">Don’t have an account? <span className="color-85"><Link to="/signup">Sign up here</Link></span></p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
