import { Box } from '@mui/system'
import React, { Suspense } from 'react'
import SideBar from '../components/sidebar/SideBar'
import Header from '../components/header/Header'
const DashboardLayout = (props) => {
  const { text } = props

  return (
    <>
      <SideBar />
      <Box className="main-boxed">
        <Header text={text} />
        <Suspense fallback={<div>Loading....</div>}>
          {props.children}
        </Suspense>
      </Box>

    </>
  )
}

export default DashboardLayout