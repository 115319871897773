import { Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../base/DashboardLayout";
import BreadCum from "../common/BreadCum";
import assetImages from '../../constant/enum';
import { addSloats, getNewAllSlot } from "../../environment/Api";
import { toast } from "react-toastify";

const timeArr = [
    { label: "12:15 AM", value: "00:15" },
    { label: "12:30 AM", value: "00:30" },
    { label: "12:45 AM", value: "00:45" },
    { label: "01:00 AM", value: "01:00" },
    { label: "01:30 AM", value: "01:30" },
    { label: "02:00 AM", value: "02:00" },
    { label: "02:30 AM", value: "02:30" },
    { label: "03:00 AM", value: "03:00" },
    { label: "03:30 AM", value: "03:30" },
    { label: "04:00 AM", value: "04:00" },
    { label: "04:30 AM", value: "04:30" },
    { label: "05:00 AM", value: "05:00" },
    { label: "05:30 AM", value: "05:30" },
    { label: "06:00 AM", value: "06:00" },
    { label: "06:30 AM", value: "06:30" },
    { label: "07:00 AM", value: "07:00" },
    { label: "07:30 AM", value: "07:30" },
    { label: "08:00 AM", value: "08:00" },
    { label: "08:30 AM", value: "08:30" },
    { label: "09:00 AM", value: "09:00" },
    { label: "09:30 AM", value: "09:30" },
    { label: "10:00 AM", value: "10:00" },
    { label: "10:30 AM", value: "10:30" },
    { label: "11:00 AM", value: "11:00" },
    { label: "11:30 AM", value: "11:30" },
    { label: "12:00 AM", value: "12:00" },
    { label: "12:30 AM", value: "12:30" },
    { label: "01:00 PM", value: "13:00" },
    { label: "01:30 PM", value: "13:30" },
    { label: "02:00 PM", value: "14:00" },
    { label: "02:30 PM", value: "14:30" },
    { label: "03:00 PM", value: "15:00" },
    { label: "03:30 PM", value: "15:30" },
    { label: "04:00 PM", value: "16:00" },
    { label: "04:30 PM", value: "16:30" },
    { label: "05:00 PM", value: "17:00" },
    { label: "05:30 PM", value: "17:30" },
    { label: "06:00 PM", value: "18:00" },
    { label: "06:30 PM", value: "18:30" },
    { label: "07:00 PM", value: "19:00" },
    { label: "07:30 PM", value: "19:30" },
    { label: "08:00 PM", value: "20:00" },
    { label: "08:30 PM", value: "20:30" },
    { label: "09:00 PM", value: "21:00" },
    { label: "09:30 PM", value: "21:30" },
    { label: "10:00 PM", value: "22:00" },
    { label: "10:30 PM", value: "22:30" },
    { label: "11:00 PM", value: "23:00" },
    { label: "11:30 PM", value: "23:30" },
    { label: "12:00 PM", value: "24:00" },
]
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/bookedslot" onClick={() => { }}>Dashboard</Link>,
    <Typography key="3" color="text.primary">Edit a Meeting</Typography>,
];

const EditSlot = () => {
    const params = useParams('id');
    const navigate = useNavigate();
    const [addSlot, setAddSlot] = useState({});
    const [deleteSlot, setDeleteSlot] = useState({});
    const [date, setDate] = useState("");
    const [sloatData, setSloatData] = useState([""]);
    const [error, setError] = useState([]);
    const [dateError, setDateError] = useState("")
    const [editData, setEditData] = useState({});
    const [duplicateError, setDuplicateError] = useState("");
    const [sloatErr, setSloatErr] = useState({});
    // const handleChange = (val, index) => {
    //     let temp = [...sloatData];
    //     temp[index] = val;
    //     setSloatData(temp);
    // }
    const handleAdd = () => {
        let temp = [...sloatData];
        temp.push("");
        setSloatData(temp);
    }
    const handleDelete = (index) => {
        console.log("skghdjg", index)
        let temp = [...sloatData];
        temp.splice(index, 1);
        setSloatData(temp);
    }
    const checkError = () => {
        if (date.length === 0) {
            setDateError("Please Enter Date.");
        } else {
            setDateError("");
        }
        setError([]);
        let validate = true;
        let err = {};
        if (editData.startTime === '') {
            err.startTime = 'Please Enter Start Time.';
            validate = false;
        }
        if (editData.endTime === '') {
            err.endTime = 'Please Enter End Time.';
            validate = false;
        }
        setSloatErr(err);

        // let err = [];
        // sloatData.map((item, index) => {
        //     if (item.length === 0) {
        //         err.push('Please Enter Value.');
        //         validate = false;
        //     } else {
        //         err.push('');
        //     }
        // })
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
        let tempDup = findDuplicates(sloatData);
        console.log("sdkfhui", tempDup)
        if (tempDup.length > 0) {
            validate = false;
            setDuplicateError('Please Enter Unique Time Slots.');
        }
        setError(err);
        return validate
    }
    useEffect(() => {
        getData();
    }, [])

    const getData = (body = { id: params.id }) => {
        getNewAllSlot(body).then((res) => {
            let temp = res;
            temp = temp[0];
            setEditData(temp);
            // console.log("Dxzcsxcz", temp.startTime)
            setDate(temp.date);
        }).catch((err) => {
            console.log(err);
        })
    }
    console.log("eifyhi", sloatData, date)

    const handleSubmit = () => {
        setDuplicateError();
        let validate = checkError();
        let temp = [...sloatData];
        temp.map((item, index) => {
            temp[index] = { time: item }
        })
        if (validate) {
            let body = {
                _id: editData._id,
                startTime: editData.startTime,
                endTime: editData.endTime,
                date: date,
            }
            addSloats(body).then((res) => {
                toast.success('Update Successfully.')
                navigate('/createameeting')
            }).catch((err) => {
                console.log("jsguds", err)
            })
        }
    }
    const handleChange = (feild, value) => {
        let temp = { ...editData };
        temp[feild] = value;
        setEditData(temp);
    }

    return (
        <>
            <DashboardLayout text="Create a Meeting">
                <div className='new-d-flex'>
                    <BreadCum
                        breadcrumbs={breadcrumbs} />
                    <div className='d-flex'>
                        {/* <button className='comman-btn' >Visit Site</button> */}
                        <NavLink to="/createameeting">
                            <button className='comman-btn' style={{ marginLeft: '1rem', marginRight: '1rem' }}><img src={assetImages.addBtnIcon} alt="" />List Of Sloats</button>
                        </NavLink>
                    </div>
                </div>
                <div className="page-start">
                    <div className="nae-class-all new-slot-centr">
                        <div className="new-add-slot">
                            <div className="slot-box">
                                <div className="slot-hedding">
                                    <h3>Edit a Slot</h3>
                                </div>
                                <div className="date-section">
                                    <div className="input-date">
                                        <input type="date" value={date} onChange={(e) => { setDate(e.target.value); }} disabled />
                                        {
                                            dateError && <div className="error">{dateError}</div>
                                        }
                                    </div>
                                    {
                                        date && <div className="d-flex top-pading-new">
                                            <div className="">{date}</div>
                                            <div className="left-right-new-class">
                                                <select className="select-new-class" onChange={(e) => { handleChange('startTime', e.target.value); }} value={editData.startTime}>
                                                    {
                                                        timeArr && timeArr.map((timeItem, timeIndex) => (
                                                            <option key={timeIndex} value={timeItem.value}>{timeItem.label}</option>
                                                        ))
                                                    }
                                                </select>
                                                {/* <input type="time" value={editData.startTime} onChange={(e) => { handleChange('startTime', e.target.value); }} /> */}

                                                {sloatErr && sloatErr.startTime && <div className="error">{sloatErr.startTime}</div>}
                                            </div>
                                            <div className="">
                                                <select className="select-new-class" onChange={(e) => { handleChange('endTime', e.target.value); }} value={editData.endTime}>
                                                    {
                                                        timeArr && timeArr.map((timeItem, timeIndex) => (
                                                            <option key={timeIndex} value={timeItem.value}>{timeItem.label}</option>
                                                        ))
                                                    }
                                                </select>
                                                {/* <input type="time" value={editData.endTime} onChange={(e) => { handleChange('endTime', e.target.value); }} /> */}
                                                {sloatErr && sloatErr.endTime && <div className="error">{sloatErr.endTime}</div>}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {duplicateError && <div className="error">{duplicateError}</div>}
                                <div className="create-slot-btn">
                                    <button className='comman-btn' type="button" onClick={() => { handleSubmit() }} ><img src={assetImages.addBtnIcon} alt="" />Update </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </DashboardLayout>
        </>
    )
}

export default EditSlot;