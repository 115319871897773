import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { Suspense, useContext, useState } from 'react';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Layout from './base/Layout';
import { TaskCntx, NotificationCntx } from './context';


const App = () => {
  const [count, setCount] = useState(0);
  const [showDot, setShowDot] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: ["Plus Jakarta Sans", "sans-serif"].join(","),
    },
  });
  return (
    <>
      <Suspense fallback={<div>Loading....</div>}>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <ThemeProvider theme={theme}>
          <TaskCntx.Provider value={{ count, setCount }}>
            <NotificationCntx.Provider value={{ showDot, setShowDot }}>
              <Router>
                <Layout />
              </Router>
            </NotificationCntx.Provider>
          </TaskCntx.Provider>
        </ThemeProvider>
      </Suspense>

    </>
  )
}

export default App;
