import React, { useEffect, useState } from 'react';
import { Link, Navigate, NavLink, useNavigate } from 'react-router-dom';
import DashboardLayout from '../../base/DashboardLayout';
import BreadCum from '../common/BreadCum';
import { Modal, Pagination, Typography } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import assetImages from '../../constant/enum';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
// import '../../assets/scss/Customer.scss'
// import ScheduleMeetting from './ScheduleMeetting';
import { Box } from '@mui/system';
import { deleteVender, deletEvent, getAllVenders, getMettings } from '../../environment/Api';
import { toast } from 'react-toastify';
import { formatTimeStr } from 'antd/lib/statistic/utils';
const BookedSlot = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [activeTab, setActiveTab] = useState("customer");
    const [openSchedule, setOpenSchedule] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [editData, setEditData] = useState({});
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getData();
    }, [])
    const breadcrumbs = [
        // <Link underline="hover" key="1" color="inherit" href="/cms" onClick={() => { }}>Home</Link>,
        <Typography key="3" color="text.primary">Dashboard</Typography>,
    ];
    const handleClickOne = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClosePopup = () => {
        setOpenSchedule(false);
    }
    const handleDeletePopup = () => {
        setDeletePopup(true)
    }
    const handleDeleteClose = () => { setDeletePopup(false); setEditData(); handleClose(); }

    const handleDelete = () => {
        let id = editData?.eventId;
        deletEvent(id).then((res) => {
            console.log("res", res);
            getData();
            handleDeleteClose();
        })
    }
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)

    const limit = 10

    useEffect(() => {
        handleChangePage();
    }, [currentPage])
    const handleChangePage = () => {
        let start = ((currentPage - 1) * limit);
        let end = start + limit;
        setStart(start);
        setEnd(end);
    }
    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        getMettings({ token: localStorage.getItem('auth-token') }).then((res) => {
            if (res?.error === "Admin is not found.") {
                localStorage.clear();
                navigate('/');
            }
            setData(res);
        }).catch((err) => {
            console.log("err", err)
        })
    }

    const formatDate = (date) => {
        date = new Date(date);
        let d = date.getDate();
        if (d < 10) {
            d = '0' + d
        }
        let m = date.getMonth() + 1;
        let y = date.getFullYear();
        return d + '/' + m + '/' + y;
    }
    const formatTime = (date) => {
        date = new Date(date);
        let h = date.getHours();
        let m = date.getMinutes();
        if (m === 0) {
            m = '00'
        }
        return h + ':' + m;
    }

    const getStatus = (date) => {
        let cDate = new Date();
        return cDate > new Date(date);
    }

    const handleCopy = (text) => {
        toast.success('Copied');
        navigator.clipboard.writeText(text);
    }
    return (
        <DashboardLayout text="Booked Slot">
            <div >
                <div className='new-d-flex new-teke'>
                    <BreadCum
                        breadcrumbs={breadcrumbs} />
                    <div className='d-flex'>
                        {/* <button className='comman-btn' >Visit Site</button> */}
                        {/* <NavLink to="/add-vender">
                            <button className='comman-btn' style={{ marginLeft: '1rem', marginRight: '1rem' }}><img src={assetImages.addBtnIcon} alt="" />Add Vendor</button>
                        </NavLink> */}
                    </div>
                </div>
                <div>
                    <div className="page-start">

                        <div className="banner-home nae-class-all">

                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-new-title">Date</TableCell>
                                            <TableCell className="table-new-title">Time</TableCell>
                                            <TableCell className="table-new-title">Full Name</TableCell>
                                            <TableCell className="table-new-title">Email</TableCell>
                                            <TableCell className="table-new-title">Phone Number</TableCell>
                                            <TableCell className="table-new-title">Company Name</TableCell>
                                            <TableCell className="table-new-title">Link</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            data && data.length > 0 && data.slice(start, end).map((item, index) => (
                                                <TableRow
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    key={index}
                                                >
                                                    <TableCell
                                                        className='pointer'
                                                        component="th" scope="row" >
                                                        <div className="new-refe ">
                                                            <span className={`${getStatus(item.startTime) ? 'dot-red' : 'dot-green'}`}></span>
                                                            {formatDate(item.startTime)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="new-refe ">
                                                            {formatTime(item.startTime)}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="faq-q-a new-refe ">
                                                            {item.fullName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="faq-q-a new-refe ">
                                                            {item.email}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="faq-q-a  ">
                                                            {item.phone}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="faq-q-a  ">
                                                            {item.companyName}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell
                                                        className='pointer'
                                                        align="left">
                                                        <div className="faq-q-a  ">
                                                            <img src={assetImages.linkurl} alt="url" onClick={() => { handleCopy(item.link); }} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div>
                                                            <Button
                                                                id="basic-button"
                                                                aria-controls={open ? 'basic-menu' : undefined}
                                                                aria-haspopup="true"
                                                                aria-expanded={open ? 'true' : undefined}
                                                                onClick={(event) => { handleClickOne(event); setEditData(item); }}
                                                            >
                                                                <img src={assetImages.trace} alt="menu" />
                                                            </Button>
                                                            <Menu
                                                                id="basic-menu"
                                                                anchorEl={anchorEl}
                                                                open={open}
                                                                onClose={handleClose}
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'basic-button',
                                                                }}
                                                            >
                                                                <MenuItem onClick={() => { handleDeletePopup(); }} ><img src={assetImages.newdelete1} style={{ paddingRight: '1.0rem' }} alt="" /> Delete</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className='pagination-css'>
                                {data?.length > 10 &&
                                    <Pagination
                                        page={currentPage}
                                        count={Math.ceil(data?.length / 10)}
                                        onChange={(e, page) => setCurrentPage(page)}
                                        variant="outlined"
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                deletePopup && <div className="delete-popup">
                    <Modal
                        open={deletePopup}
                        onClose={handleDeleteClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box className="modal-potion">
                            <div className="modal-hedding">
                                <h3>&nbsp;&nbsp;Are you sure you want to Delete?</h3>
                                <div className="close-faq" onClick={handleDeleteClose}>
                                    <button ><img src={assetImages.closebtn} alt="close" /></button>
                                </div>
                            </div>
                            <div className="modal-delete">
                                <div className="can-de">
                                    <button onClick={handleDeleteClose} className="cancel">Cancel</button>
                                    <button onClick={handleDelete} className="delete">Delete</button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                </div>
            }
        </DashboardLayout>
    )
}

export default BookedSlot;