import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import assetImages from "../../constant/enum";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactTooltip from 'react-tooltip';
// import { getNewNotificationsData, getTaskCount } from "../../environment/Api";
import { NotificationCntx, TaskCntx } from "../../context";

const SideBar = () => {
  const { count, setCount } = useContext(TaskCntx);
  const { showDot, setShowDot } = useContext(NotificationCntx);
  // console.log("wdsrftgbh", count)
  const [show, setShow] = useState(window.innerWidth > 1024 ? false : true);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const toggleBtn = () => {
    if (window.innerWidth > 1024) {
      setShow((prevstate) => !prevstate);
      document.body.classList.toggle("main-page-area", !show);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 1024) {
      setShow(true);
      document.body.classList.toggle("main-page-area", true);
    }
    // getTaskCountData();
    // getNewNotifications();
  }, []);
  const Logout = () => {
    localStorage.clear();
    navigate("/");
  };


  return (
    <>
      <Box
        className={`bg-blue-gradiant ${show ? "sidebar-main-area side-small" : "sidebar-main-area"}`}
      >
        <div className="inner-box">
          <div className="first-row-logo">
            <div className="logo">
              <img src={assetImages.logo} alt="logo" />
            </div>
            {
              window.innerWidth < 1024 &&
              <div className="hamburg" onClick={() => { !show && toggleBtn() }}>
                <img src={assetImages.menu} alt="img" />
              </div>
            }
          </div>
          <div className="inner-menu-itm">
            <ul className="menu-items-area">

              <li>
                <NavLink to="/bookedslot">
                  <div className="inner-block">
                    <img src={assetImages.dashbicon} alt="img" />
                    <span >Booked Slot</span>
                    {/* {role === 'SUBADMIN' && item.name === "Task" ? <div className="count-task"> {count}</div> : ''}
                          {showDot === true && item.name === "Notifications" ? <div className="notification-dot"></div> : ''} */}
                    {/* <img src={item.rightArrow} alt="arrow-right" /> */}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/createameeting" >
                  <div className="inner-block">
                    <img src={assetImages.listing} alt="img" />
                    <span >Create a meeting</span>
                    {/* <img src={item.rightArrow} alt="arrow-right" /> */}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/setting" >
                  <div className="inner-block">
                    <img src={assetImages.user01} alt="img" />
                    <span >User Profile</span>
                    {/* <img src={item.rightArrow} alt="arrow-right" /> */}
                  </div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/cms/contact-us" >
                  <div className="inner-block">
                    <img src={assetImages.user01} alt="img" />
                    <span >Contact Us</span>
                    {/* <img src={item.rightArrow} alt="arrow-right" /> */}
                  </div>
                </NavLink>
              </li>
              <li>
                <div className="inner-block" onClick={Logout}>
                  <img src={assetImages.logout01} alt="img" />
                  <span>Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Box>
      <ReactTooltip className="tooltip-new" />
    </>
  );
};

export default SideBar;
